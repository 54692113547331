import { useEffect, useState } from "react";
import ReqeustPopup from "../Requests/RequestPopup";

export default function UberBody(props) {
  const [date, setDate] = useState(null);
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(null);
  const [showing, setShowing] = useState(false);
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  useEffect(() => {
    const d = new Date(props.item.createdAt.replace(" ", "T"));
    setDate(d);
  }, []);

  useEffect(() => {
    if (props.item) {
      setIsLoading(true);
      fetch(`/api/tasks/requestid/${props.item.DocumentID}`)
        .then((res) => {
          if (res.ok) {
            return res.json();
          } else throw Error("");
        })
        .then((data) => {
          setIsLoading(false);
          if (data && data.length > 0) {
            let c = 0;
            if (data[0].Received) c += 1;
            if (data[0].Checking) c += 1;
            if (data[0].ReportSubmitted) c += 1;
            if (data[0].Assigned) c += 1;
            setData(c);
          }
        })
        .catch((e) => {
          setIsLoading(false);
        });
    }
  }, [props.item]);

  function getRandomColor() {
    var letters = "0123456789ABCDEF";
    var color = "#";
    for (var i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }

  function formatAMPM(date) {
    var hours = date?.getHours();
    var minutes = date?.getMinutes();
    var ampm = hours >= 12 ? "pm" : "am";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? "0" + minutes : minutes;
    var strTime = hours + ":" + minutes + " " + ampm;
    return strTime;
  }

  return (
    <>
      <div
        onClick={() => {
          if (!props.task) {
            setShowing(true);
          } else window.location.href = "/ridertask/" + props.item.DocumentID;
        }}
        className="accordion-body"
      >
        <div style={{ backgroundColor: getRandomColor() }} className="date">
          <h1>{date?.getDate()}</h1>
          <p>
            {monthNames[date?.getMonth()]} {date?.getFullYear()}
          </p>
          <p>{formatAMPM(date)}</p>
        </div>
        <div className="bdy">
          <h4>{props.item.Title}</h4>
          <div className="list">
            <div>
              <p>Name: {props.item.Name}</p>
              <p>Phone: {props.item.Phone}</p>
              <p>Type: {props.item.Category}</p>
            </div>

            <div>
              <p>
                <b> Checks: </b> Identity Check, Police Clearance Check, Driving
                License Check, and PSV Check
              </p>
            </div>

            <div className="progress">
              <h4
                style={{
                  backgroundColor:
                    props.item.Report === null ? "orangered" : "green",
                }}
              >
                {props.item.Report === null ? "Pending" : "Complete"}
              </h4>
            </div>
          </div>
        </div>
      </div>
      {showing && (
        <>
          {!props.task && (
            <ReqeustPopup
              item={props.item}
              refresh={props.refresh}
              setRefresh={props.setRefresh}
              setShowing={setShowing}
            />
          )}
        </>
      )}
    </>
  );
}

import { useEffect, useState } from "react";
import WaveLoading from "../Util/WaveLoading";
import { BsInfoSquare } from "react-icons/bs";
import { GrInProgress } from "react-icons/gr";
import { BsFillPersonCheckFill } from "react-icons/bs";
import ReportInput from "../Reports/ReportsManagement/ReportInput";
import SubjectsPopup from "./SubjectsPopup";
import "../../Styles/requests.scss";
import { useLocation } from "react-router-dom";

export default function ReqeustPopup(props) {
  var jwt = require("jsonwebtoken");
  const [isLoading, setIsLoading] = useState(null);
  const [error, setError] = useState("");
  const [data, setData] = useState(null);
  const [fo, setFO] = useState(null);
  const [fodata, setFOData] = useState(null);
  const [refresh, setRefresh] = useState(false);
  const [jdets, setJDets] = useState(null);
  const [clicked, setClicked] = useState(null);
  const [udets, setUDets] = useState(null);
  const [tt, setTT] = useState(null);
  const [ct, setCT] = useState(null);
  const [role, setRole] = useState(null);
  const location = useLocation();
  const id = location.pathname.split("/")[3];

  const [body, setBody] = useState({
    RequestID: id,
    UserID: null,
    Assigned: true,
  });

  useEffect(() => {
    const token = localStorage.getItem("gfgfgggn");

    if (token) {
      try {
        var decoded = jwt.decode(token);
        if (Date.now() >= decoded.exp * 1000) {
          window.location.href = "/login";
        } else {
          setRole(decoded.Role);
        }
      } catch (error) {
        window.location.href = "/login";
      }
    } else {
      window.location.href = "/login";
    }
  }, []);

  useEffect(() => {
    setIsLoading(true);
    fetch(`/api/subjects/checks/byjobid/${id}`)
      .then((res) => {
        if (res.ok) return res.json();
        else throw Error();
      })
      .then((data) => {
        if (data.length > 0) {
          let total = 0;
          let count = 0;
          data.map((item) => {
            const it = JSON.parse(item.Checks);
            total = total + it.length;
            it.map((i) => {
              if (!i.Status) {
                count += 1;
              }
            });
          });

          setTT(total);
          setCT(count);
        }
        setIsLoading(false);
      })
      .catch((e) => {
        setIsLoading(false);
      });
  }, [refresh]);

  useEffect(() => {
    setIsLoading(true);

    fetch(`/api/requests/${id}`)
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else throw Error("");
      })
      .then((data) => {
        setIsLoading(false);
        setJDets(data);
      })
      .catch((e) => {
        setIsLoading(false);
      });
  }, [props.refresh, refresh]);

  useEffect(() => {
    if (jdets) {
      setIsLoading(true);
      fetch(`/api/mobile/${jdets?.UserID}`)
        .then((res) => {
          if (res.ok) {
            return res.json();
          } else throw Error("");
        })
        .then((data) => {
          setIsLoading(false);
          setUDets(data);
        })
        .catch((e) => {
          setIsLoading(false);
        });
    }
  }, [jdets]);

  useEffect(() => {
    if (id) {
      setData(null);
      setIsLoading(true);
      fetch(`/api/tasks/requestid/${id}`)
        .then((res) => {
          if (res.ok) return res.json();
          else throw Error();
        })
        .then((data) => {
    
          if (data.length > 0) {
            setData(data[0]);
          }
          setIsLoading(false);
        })
        .catch((e) => {
          setIsLoading(false);
        });
    }
  }, [refresh, id]);

  function searchByName(q) {
    fetch(`/api/auth/seachbyname/${q}`)
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else throw Error();
      })
      .then((data) => {
        setFO(data);
      })
      .catch((e) => {});
  }

  const createDocument = (e) => {
    setError("");
    const chck = Object.values(body);
    let valid = true;
    chck.map((item) => {
      if (item === null) {
        valid = false;
      }
    });
    if (!valid) return setError("All fields are required!");
    setIsLoading(true);

    fetch("/api/tasks/create", {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(body),
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else throw Error("");
      })
      .then((data) => {
        setIsLoading(false);
        if (data.success) {
          setError(data.success);
          setRefresh(!refresh);
        } else {
          setError(data.error);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        setError("Oops! Something went wrong!");
      });
  };

  const updateTask = (e) => {
    setError("");
    const chck = Object.values(body);
    let valid = true;
    chck.map((item) => {
      if (item === null) {
        valid = false;
      }
    });
    if (!valid) return setError("All fields are required!");
    setIsLoading(true);

    fetch(`/api/tasks/${data?.ID}`, {
      method: "PUT",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(body),
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else throw Error("");
      })
      .then((data) => {
        setIsLoading(false);
        if (data.success) {
          setError(data.success);
          setRefresh(!refresh);
        } else {
          setError(data.error);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        setError("Oops! Something went wrong!");
      });
  };

  const List = (params) => {
    return (
      <div
        onClick={() => {
          let d = body;
          d.UserID = params.item.UserID;
          setBody(d);
          setFOData(params.item);
          setFO(null);
        }}
        className="s_list"
      >
        <p>
          {params.item.Name} - {params.item.Role}
        </p>
      </div>
    );
  };

  function deleteRequest() {
    setIsLoading(true);
    fetch(`/api/requests/${id}`, { method: "DELETE" })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else throw Error("");
      })
      .then((data) => {
        setIsLoading(false);
        if (data.success) {
          alert(data.success);
          window.location.href = "/requests/List";
        } else alert(data.error);
     
      })
      .catch((e) => {
        setIsLoading(false);
        alert("Something went wrong!");
      });
  }

  return (
    <div className="requests">
      <div className="request_popup">
        <div className="pcontainer">
          <div className="tbar">
            <h3>{jdets && jdets?.Title}</h3>

            <p>
              Requests{" "}
              <i
                onClick={() => {
                  window.location.href = "/requests/List";
                }}
                className="fa fa-arrow-right"
              ></i>
            </p>
          </div>

          <div className="wcontent">
            <div className="div3equal">
              <div>
                <h5>
                  <BsInfoSquare /> Basic Info
                </h5>
                <div className="details">
                  <h3>Job Details</h3>
                  <div className="u_list">
                    <p>
                      <b>Title: </b> {jdets && jdets?.Title}
                    </p>
                    <p>
                      <b>Description: </b> {jdets && jdets?.Description}
                    </p>
                    <p>
                      <b>Consent Form: </b>{" "}
                      {jdets &&
                        jdets?.Consent?.substring(13, jdets?.Consent?.length)}
                    </p>
                    <p>
                      <b>No of Subjects: </b>
                      {data?.Clients ? data?.Clients.length : 0}
                    </p>
                  </div>

                  <h3>Client Details</h3>
                  <div className="u_list">
                    <p>
                      <b>Name: </b> {udets && udets?.Name}
                    </p>
                    <p>
                      <b>Phone: </b> {udets && udets?.Phone}
                    </p>
                    <p>
                      <b>Email: </b> {udets && udets?.Email}
                    </p>
                    <p>
                      <b>Organisation: </b> {udets && udets?.Organisation}
                    </p>
                  </div>
                </div>
                {role && role === "Super Admin" && (
                  <button
                    onClick={() => {
                      deleteRequest();
                    }}
                    style={{ backgroundColor: "red" }}
                  >
                    Delete Request
                  </button>
                )}
              </div>
              <div>
                <h5>
                  <GrInProgress /> Progress
                </h5>
                <div className="div prg">
                  <Item txt="Received" status={true} />
                  <Item
                    txt="Assigned to Staff"
                    status={data ? data.Received : null}
                  />
                  <Item
                    txt="Actual Checks"
                    status={data ? data.Checking : null}
                    tally={
                      tt && ct
                        ? ` ${tt - ct}/${tt} --- ${(
                            ((tt - ct) / tt) *
                            100
                          ).toFixed(0)}%`
                        : null
                    }
                  />

                  <Item
                    txt="Report Submission"
                    status={data ? data.ReportSubmitted : null}
                  />
                </div>
              </div>
              <div>
                <h5>
                  <BsFillPersonCheckFill /> Assigned To
                </h5>
                <div className="u_list">
                  <div>
                    {data ? (
                      <>
                        <h4>Assigned Staff</h4>
                        <p>Name: {data.Name}</p>
                        <p>Phone: {data.Phone}</p>
                        <p>Role: {data.Role}</p>
                        <p>
                          Date: {data.createdAt.split("T")[0]}{" "}
                          {data.createdAt.split("T")[1].substring(0, 5)}
                        </p>
                        <br />
                      </>
                    ) : (
                      <>
                        <h4>No staff Assigned</h4>
                        <div className="search">
                          <ReportInput
                            type="text"
                            handleChange={(e) => {
                              searchByName(e);
                            }}
                            label="Name"
                            hint="Type to search"
                          />
                          {fo && (
                            <div className="search_list">
                              {fo.map((item, i) => {
                                return <List key={i} item={item} type="fo" />;
                              })}
                            </div>
                          )}
                        </div>

                        <h6>{error}</h6>

                        <button
                          onClick={() => {
                            createDocument();
                          }}
                        >
                          Submit
                        </button>
                      </>
                    )}

                    {data && role && role === "Super Admin" && (
                      <>
                        <br />
                        <h4>Assign to Another Staff</h4>
                        <div className="search">
                          <ReportInput
                            type="text"
                            handleChange={(e) => {
                              searchByName(e);
                            }}
                            label="Name"
                            hint="Type to search"
                          />
                          {fo && (
                            <div className="search_list">
                              {fo.map((item, i) => {
                                return <List key={i} item={item} type="fo" />;
                              })}
                            </div>
                          )}
                        </div>
                        <div>
                          {fodata && (
                            <SelectedUser
                              title="Selected Staff"
                              clearEvent={() => {
                                let d = body;
                                d.UserID = null;
                                setBody(d);
                                setFOData(null);
                              }}
                              data={fodata}
                            />
                          )}
                        </div>

                        <h6>{error}</h6>

                        <button
                          onClick={() => {
                            updateTask();
                          }}
                        >
                          Submit
                        </button>
                      </>
                    )}
                    <br />
                  </div>
                </div>
              </div>
            </div>

            {isLoading && <WaveLoading />}
          </div>
        </div>
        {jdets && (
          <SubjectsPopup
            JobID={id}
            Title={data?.Title}
            setShowing={setJDets}
            setClicked={setClicked}
            refresh={refresh}
            setRefresh={setRefresh}
            task={props.task}
          />
        )}
      </div>
    </div>
  );
}

const Item = (props) => {
  const [color, setColor] = useState("red");

  useEffect(() => {
    switch (props.status) {
      case true:
        setColor("green");
        break;
      case false:
        setColor("orange");
        break;
      case null:
        setColor("red");
        break;
      default:
        setColor("red");
        break;
    }
  }, [props.status]);

  return (
    <div className="item">
      <div style={{ backgroundColor: color }}></div>
      <p>
        {props.txt} <b>{props.tally ? props.tally : ""}</b>
      </p>
    </div>
  );
};

const SelectedUser = (props) => {
  return (
    <>
      {props.data && (
        <div className="selected_staff">
          <div
            onClick={() => {
              props.clearEvent();
            }}
            className="fa fa-times"
          ></div>
          <p>{props.data.Name}</p>
          <p>{props.data.Phone}</p>
        </div>
      )}
    </>
  );
};

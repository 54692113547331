import { useEffect, useState } from "react";
import ReqeustPopup from "../Requests/RequestPopup";

export default function UberBody(props) {
  const [date, setDate] = useState(null);
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(null);
  const [showing, setShowing] = useState(false);
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  useEffect(() => {
    const d = new Date(props.item.createdAt.replace(" ", "T"));
    setDate(d);
  }, []);

  useEffect(() => {
    if (props.item) {
      console.log(props.item);

      let c = 0;
      if (props.item.IDCheck != null) c += 1;
      if (props.item.PSVCheck != null) c += 1;
      if (props.item.DLCheck != null) c += 1;
      if (props.item.PoliceCheck != null) c += 1;
      setData(c);
    }
  }, [props.item]);

  function formatAMPM(date) {
    var hours = date?.getHours();
    var minutes = date?.getMinutes();
    var ampm = hours >= 12 ? "pm" : "am";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? "0" + minutes : minutes;
    var strTime = hours + ":" + minutes + " " + ampm;
    return strTime;
  }

  return (
    <>
      <div
        onClick={() => {
          if (props.type === "driver") {
            window.location.href = "/ubertask/" + props.item.DocumentID;
          } else window.location.href = "/ridertask/" + props.item.DocumentID;
        }}
        className="accordion-body"
      >
        <div className="date">
          <div>
            <h1>{date?.getDate()}</h1>
            <p>
              {monthNames[date?.getMonth()]} {date?.getFullYear()}
            </p>
            <p>{formatAMPM(date)}</p>
          </div>
        </div>
        <div className="details">
          <h4>{props.item.Name}</h4>
          <p>Phone: {props.item.Phone}</p>
          <p>Type: {props.item.Category}</p>
          <p>
            <b> Checks: </b> Identity Check, Police Clearance Check, Driving
            License Check, and PSV Check
          </p>
        </div>
        <div className="deadline">
          <h4>1 Day</h4>
        </div>
        <div className="progress">
          <div
            style={{
              height: data ? `${((data / 4) * 100).toFixed(0)}%` : "0%",
            }}
            className="prg"
          ></div>
          <p>
            <span>{((data / 4) * 100).toFixed(0)}%</span>
          </p>
        </div>
      </div>
      {showing && (
        <>
          {!props.task && (
            <ReqeustPopup
              item={props.item}
              refresh={props.refresh}
              setRefresh={props.setRefresh}
              setShowing={setShowing}
            />
          )}
        </>
      )}
    </>
  );
}

import { faUserCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useRef, useState } from "react";

export default function Header(props) {
  var jwt = require("jsonwebtoken");
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [currentUser, setCurrentUser] = useState("");
  const [clicked, setClicked] = useState(false);
  const [details, setDetails] = useState("");
  const [login, setLogin] = useState("");
  const [logout, setLogout] = useState("");

  const toggleMenu = () => {
    setClicked(!clicked);
  };

  useEffect(() => {
    const token = localStorage.getItem("gfgfgggn");

    if (token) {
      try {
        var decoded = jwt.decode(token);
        if (Date.now() >= decoded.exp * 1000) {
          window.location.href = "/login";
        } else {
          const lg = convertTime(decoded?.iat).split("GMT")[0];
          const lo = convertTime(decoded?.exp).split("GMT")[0];
          setDetails(decoded?.Name);
          setLogin(lg.substring(lg.length - 9, lg.length));
          setLogout(lo.substring(lo.length - 9, lo.length));
        }
      } catch (error) {
        window.location.href = "/login";
      }
    } else {
      window.location.href = "/login";
    }
  }, [isAuthenticated]);

  function convertTime(dt) {
    const date = new Date(dt * 1000);
    return date.toString();
  }

  return (
    <div>
      <div className="header">
        <i
          className="fa fa-bars"
          onClick={() => {
            props.setShowing(!props.showing);
          }}
        ></i>
        <div className="right">
          <p>
            {details}
            <span>
              <b> Login: </b> {login.substring(0, 5)}
            </span>
            <span>
              <b> Logout: </b> {logout.substring(0, 5)}
            </span>
            {" "}
            <FontAwesomeIcon icon={faUserCircle} className="user" />
          </p>
        </div>
      </div>
    </div>
  );
}

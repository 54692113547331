import "../../Styles/querybuilder.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Select from "../Util/Select";
import { faAdd, faDownload, faTimes } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useRef, useState } from "react";
import Loading from "../Util/Loading";
import Input from "../Util/Input";
import Pagination from "../Util/Pagination";

export default function QueryBuilder(props) {
  const [loading, setLoading] = useState(false);
  const [active, setActive] = useState("Drivers");
  const [reporting, setReporting] = useState(false);
  const [columns, setColumns] = useState(null);
  const [data, setData] = useState(null);
  const [details, setDetails] = useState(null);
  const [tableData, setTableData] = useState([]);
  const [tables, setTables] = useState([]);
  const [cols, setCols] = useState([]);
  const [selected, setSelected] = useState(null);
  const [filters, setFilters] = useState([]);
  const [retrieveData, setRetrieveData] = useState(false);
  const [offset, setOffset] = useState(0);
  const col = useRef();
  const op = useRef();
  const vl = useRef();

  function addFilter() {
    const cl = col.current.value;
    const o = op.current.value;
    const v = vl.current.value;
    if (cl != "" && o != "" && v != "") {
      let d = {
        column: cl,
        operator: o,
        value: v,
      };
      setFilters([...filters, d]);
    }
  }

  useEffect(() => {
    if (retrieveData === true) {
      getData();
    } else {
      setDetails(null);
    }
  }, [retrieveData, offset]);

  function getData() {
    setData(null);
    setDetails(null);
    setLoading(true);
    fetch(`/api/myreports/getdata/UberLists/public/${active}/${offset}`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({ filters: filters }),
    })
      .then((res) => {
        if (res.ok) return res.json();
        else throw Error("");
      })
      .then((data) => {
        setLoading(false);
        if (data.data.length > 0) {
          let dt = data.data[0];
          delete dt.createdAt;
          delete dt.updatedAt;
          const c = Object.keys(dt).filter((key) => !key.includes("Type"));
          setColumns(c.slice(0, 9));
          setData(data);
          setDetails(data.data);
        }
      })
      .catch((e) => {
        setLoading(false);
      });
  }

  const saveData = (data) => {
    if (data.length > 0) {
      let rows = [];
      rows.push(Object.keys(data[0]));
      data.map((item) => {
        rows.push(Object.values(item));
      });
      let csvContent =
        "data:text/csv;charset=utf-8," +
        rows.map((e) => e.join(",")).join("\n");

      var encodedUri = encodeURI(csvContent);
      var link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", "data.csv");
      document.body.appendChild(link);
      link.click();
    }
  };

  return (
    <div className="querybuilder">
      <div className="top">
        <div className="options">
          <div
            onClick={() => {
              setActive("Drivers");
            }}
            className={active == "Drivers" ? "active" : "litem"}
          >
            <div></div>
            <p>Uber Drivers</p>
          </div>
          <div
            onClick={() => {
              setActive("Riders");
            }}
            className={active == "Riders" ? "active" : "litem"}
          >
            <div></div>
            <p>Uber Riders</p>
          </div>
          <div className="bts">
            <h6
              onClick={() => {
                setReporting(true);
              }}
            >
              Add Filter
            </h6>
            <h6
              onClick={() => {
                setRetrieveData(true);
                setOffset(0);
                getData();
              }}
            >
              Retrieve Data
            </h6>
          </div>
        </div>
        <hr />

        {reporting && (
          <div className="add">
            <Select
              ref={col}
              label="Column"
              data={[
                "No",
                "FirstNames",
                "LastName",
                "Phone",
                "IDNo",
                "BGCResults",
                "Date",
                "Comments",
              ]}
            />
            <Select
              ref={op}
              label="Operator"
              data={["=", ">", ">=", "<", "<=", "ILIKE"]}
            />
            <Input ref={vl} label="Value" />
            <FontAwesomeIcon
              onClick={() => {
                addFilter();
                setReporting(false);
              }}
              className="btn"
              icon={faAdd}
            />
          </div>
        )}

        <div className="div3equal">
          {filters.map((item, i) => {
            return (
              <TItem
                key={i}
                index={i}
                item={item}
                setFilters={setFilters}
                filters={filters}
              />
            );
          })}
        </div>
      </div>
      <div className="list">
        <div className="div1auto">
          <h4>Data</h4>
          <FontAwesomeIcon
            onClick={() => {
              if (data) {
                saveData(data);
              }
            }}
            className="download"
            icon={faDownload}
          />
        </div>
        <div className="data">
          <div className="head">
            {columns &&
              columns.map((e, i) => {
                return <h4 key={i}>{e}</h4>;
              })}
          </div>
          {details &&
            details.length > 0 &&
            details.map((item, i) => {
              return <Item key={i} item={item} index={i + offset * 12} />;
            })}

          {data && (
            <Pagination
              totalPages={data?.total[0].count}
              currentPage={offset}
              handlePageChange={(v) => {
                setOffset(v);
              }}
            />
          )}
        </div>
      </div>
      {loading && <Loading />}
    </div>
  );
}

const TItem = (props) => {
  function removeItem() {
    const newArray = [...props.filters];
    newArray.splice(props.index, 1);
    props.setFilters(newArray);
  }
  return (
    <div className="item">
      <p>{props.item.column}</p>
      <p>{props.item.operator}</p>
      <p>{props.item.value}</p>
      <FontAwesomeIcon
        onClick={() => {
          removeItem();
        }}
        icon={faTimes}
        className="fas"
      />
    </div>
  );
};

const Item = (props) => {
  const [values, setValues] = useState(null);
  const [clicked, setClicked] = useState(false);
  useEffect(() => {
    if (props.item) {
      let dt = props.item;
      delete dt.createdAt;
      delete dt.updatedAt;
      const v = Object.values(
        Object.fromEntries(
          Object.entries(dt).filter(([key]) => !key.includes("Type"))
        )
      );
      // Object.values(dt);
      setValues(v.slice(0, 9));
    }
  }, [props.item]);

  return (
    <>
      <div
        onClick={() => {
          setClicked(true);
        }}
        className="item"
      >
        {values &&
          values.map((e, i) => {
            return <p key={i}>{e}</p>;
          })}
      </div>
      {clicked && (
        <Popup item={props.item} setClicked={setClicked} index={props.index} />
      )}
    </>
  );
};

const Popup = (props) => {
  const [values, setValues] = useState(null);
  const [cols, setCols] = useState(null);
  const [data, setData] = useState(null);
  useEffect(() => {
    if (props.item) {
      let ds = props.item;
      delete ds.geom;
      setData([ds]);
      let dt = props.item;
      delete dt.geom;
      delete dt.ID;
      delete dt.id;

      const v = Object.values(dt);
      const c = Object.keys(dt);
      setValues(v);
      setCols(c);
    }
  }, [props.item]);

  const DItem = (params) => {
    return (
      <div className="ditem">
        <h4>{params.column}</h4>
        <p>{params.value}</p>
      </div>
    );
  };

  const saveData = (data) => {
    if (data.length > 0) {
      let rows = [];
      rows.push(Object.keys(data[0]));
      data.map((item) => {
        rows.push(Object.values(item));
      });
      let csvContent =
        "data:text/csv;charset=utf-8," +
        rows.map((e) => e.join(",")).join("\n");

      var encodedUri = encodeURI(csvContent);
      var link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", "data.csv");
      document.body.appendChild(link);
      link.click();
    }
  };

  return (
    <div className="popup">
      <div className="container">
        <div className="div1auto">
          <h4>Data Item {props.index}</h4>
          <FontAwesomeIcon
            onClick={() => {
              props.setClicked(false);
            }}
            color="white"
            icon={faTimes}
          />
        </div>
        <div className="data">
          <div className="div2equal">
            {cols &&
              cols.map((c, i) => {
                return <DItem key={i} column={c} value={values[i]} />;
              })}
          </div>
        </div>
        <button
          onClick={() => {
            if (data) {
              saveData(data);
            }
          }}
        >
          Download Record
        </button>
      </div>
    </div>
  );
};

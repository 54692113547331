import { useEffect, useState } from "react";
import GaugeChart from "react-gauge-chart";
import { MdAddTask } from "react-icons/md";
import "../../Styles/uber.scss";
import CustomBarChart from "../Stats/CustomBarChart";
import MyError from "../Util/MyError";
import Pagination from "../Util/Pagination";
import WaveLoading from "../Util/WaveLoading";
import UberBody from "./UberBody";

export default function Uber(props) {
  var jwt = require("jsonwebtoken");
  const [data, setData] = useState(null);
  const [tasks, setTasks] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showing, setShowing] = useState(true);
  const [active, setActive] = useState("Active Tasks");
  const [refresh, setRefresh] = useState(false);
  const [offset, setOffset] = useState(0);
  const [id, setId] = useState(null);
  const [user, setUser] = useState("");
  const [type, setType] = useState("driver");

  const handleResize = () => {
    if (window.innerWidth < 768) {
      setShowing(false);
    } else {
      setShowing(true);
    }
  };

  useEffect(() => {
    handleResize();
    if (props.url.includes("Driver")) {
      setType("driver");
    } else {
      setType("rider");
    }
  }, []);

  useEffect(() => {
    if (id) {
      fetch(`/api/uber/stats/top/${props.url}/${id}`)
        .then((res) => {
          if (res.ok) return res.json();
          else throw Error("");
        })
        .then((data) => {
          setData(data);
        })
        .catch((e) => {});
    }
  }, [id, refresh]);

  useEffect(() => {
    const token = localStorage.getItem("gfgfgggn");

    if (token) {
      try {
        var decoded = jwt.decode(token);
        if (Date.now() >= decoded.exp * 1000) {
          window.location.href = "/login";
        } else {
          setId(decoded.UserID);
          setUser(decoded.Name);
        }
      } catch (error) {
        window.location.href = "/login";
      }
    } else {
      window.location.href = "/login";
    }
  }, []);

  useEffect(() => {
    if (!id) return;
    setTasks(null);
    if (active === "Active Tasks") {
      setLoading(true);

      fetch(`/api/uber/admpaginated/Received/${props.url}/${offset * 12}`, {
        method: "get",
        credentials: "include",
      })
        .then((res) => {
          if (!res.ok) {
            throw Error("Could not fetch messages!!!");
          }
          return res.json();
        })
        .then((data) => {
          setLoading(false);
          setTasks(data);
        })
        .catch((err) => {
          setLoading(false);
        });
    } else {
      setLoading(true);
      fetch(`/api/uber/admpaginated/Complete/${props.url}/${offset * 12}`, {
        method: "get",
        credentials: "include",
      })
        .then((res) => {
          if (!res.ok) {
            throw Error("Could not fetch messages!!!");
          }
          return res.json();
        })
        .then((data) => {
          setLoading(false);
          setTasks(data);
        })
        .catch((err) => {
          setLoading(false);
        });
    }
  }, [offset, active, refresh, id, props.url]);

  const Item = (params) => {
    return (
      <div
        onClick={() => {
          props.setSelected(params.txt);
        }}
        className={params.txt === props.active ? "active" : "item"}
      >
        <div></div>
        <h3>{params.txt}</h3>
      </div>
    );
  };

  return (
    <div>
      <div className="taskstats">
        <div className="left">
          <div className="bar">
            <div></div>
            <h2>{props.url} Tasks</h2>
          </div>
          <div className="outer">
            <div className="ll">
              <div className="section">
                <div className="single">
                  <MdAddTask className="ts" />
                  <div>
                    <h4>{data ? data?.AllTasks : 0}</h4>
                    <p>All Tasks</p>
                    <p>
                      Completed: <b>{data ? data?.Complete : 0}</b>
                    </p>
                  </div>
                </div>
              </div>
              <div className="section">
                <div className="gauge">
                  <h3>Completion Rate</h3>
                  <GaugeChart
                    className="gg"
                    id="gauge-chart2"
                    nrOfLevels={3}
                    colors={["red", "orange", "green"]}
                    arcWidth={0.3}
                    percent={
                      data ? (data?.Complete / data?.AllTasks).toFixed(2) : 0
                    }
                    textColor="gray"
                  />
                </div>
              </div>
            </div>
            <div className="section">
              <h3>Monthly Performance</h3>
              <CustomBarChart
                aspect={1.8}
                data={data ? data.Bar : null}
                color="orange"
              />
            </div>
          </div>
        </div>
        <div className="right">
          <div className="bar">
            <div></div>
            <h2>Performance</h2>
          </div>
          <div className="outer">
            <div className="section">
              <div className="perf">
                <div className="hl">
                  <h4>{data ? data?.Overdue : 0}</h4>
                  <h5>Overdue</h5>
                </div>
                <h2>Task Due Date</h2>
                <div className="div1auto">
                  <div style={{ backgroundColor: "red" }}></div>
                  <p>1 Day:</p>
                  <h6>{data ? data?.Day1 : 0}</h6>
                </div>
                <div className="div1auto">
                  <div style={{ backgroundColor: "orange" }}></div>
                  <p>2 Days:</p>
                  <h6>{data ? data?.Day2 : 0}</h6>
                </div>
                <div className="div1auto">
                  <div style={{ backgroundColor: "green" }}></div>
                  <p> 3 or More Days:</p>
                  <h6>{data ? data?.Day3 : 0}</h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />

      <div className="uberbars">
        <div className="cont">
          <Item txt="New Drivers" />
          <Item txt="Driver Renewals" />
          <Item txt="New Riders" />
          <Item txt="Rider Renewals" />
          <Item txt="Data" />
        </div>
      </div>
      <br />
      <br />

      <div className="selector">
        <div className="cont">
          <Selector txt="Active Tasks" active={active} setActive={setActive} />
          <Selector
            txt="Completed Tasks"
            active={active}
            setActive={setActive}
          />
        </div>
      </div>

      <div className="tcontent">
        {tasks && tasks?.data?.length > 0 ? (
          tasks?.data?.map((item, index) => {
            return (
              <UberBody
                item={item}
                key={index}
                task={true}
                refresh={refresh}
                setRefresh={setRefresh}
                type={type}
                date={item.createdAt.split("T")[0]}
                file={item.NationalID}
              />
            );
          })
        ) : (
          <MyError txt="No Active Tasks" />
        )}
        {tasks && tasks?.data?.length > 0 && (
          <Pagination
            totalPages={Math.ceil(tasks.total / 12)}
            currentPage={offset}
            handlePageChange={(v) => {
              setOffset(v);
            }}
          />
        )}
      </div>
      {loading && <WaveLoading />}
    </div>
  );
}

const Selector = (props) => {
  return (
    <div className={props.active === props.txt ? "active" : "item"}>
      <div></div>
      <h3
        onClick={() => {
          props.setActive(props.txt);
        }}
      >
        {props.txt}
      </h3>
    </div>
  );
};

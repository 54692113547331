import "../../Styles/invoicing.scss";
import { FaFileInvoiceDollar } from "react-icons/fa";
import React, { useState, useEffect, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEnvelope,
  faMailBulk,
  faMailForward,
  faPhone,
  faSearch,
  faUser,
  faUserPlus,
} from "@fortawesome/free-solid-svg-icons";
import Loading from "../Util/Loading";
import Pagination from "../Util/Pagination";
import CustomBarChart from "../Stats/CustomBarChart";
import GaugeChart from "react-gauge-chart";
import { MdAddTask } from "react-icons/md";
import { FiRefreshCcw } from "react-icons/fi";
import { BsEmojiSmile } from "react-icons/bs";

export default function Invoicing(props) {
  const [offset, setOffset] = useState(0);
  const [data, setData] = useState(null);
  const [sdata, setSData] = useState(null);
  const [loading, setLoading] = useState(null);
  const [userID, setUserID] = useState(null);
  const [refresh, setRefresh] = useState(false);
  const [user, setUser] = useState("");
  var jwt = require("jsonwebtoken");

  useEffect(() => {
    fetch(`/api/invoices/getadminstats`)
      .then((res) => {
        if (res.ok) return res.json();
        else throw Error("");
      })
      .then((data) => {
        setSData(data);
      })
      .catch((e) => {});
  }, [refresh]);

  useEffect(() => {
    const token = localStorage.getItem("gfgfgggn");
    if (token) {
      try {
        var decoded = jwt.decode(token);
        setUser(decoded.Name);
        if (decoded.Role === "Admin" || decoded.Role === "Super Admin") {
        } else window.location.href = "/";
      } catch (error) {}
    }
  }, []);

  useEffect(() => {
    setLoading(true);
    fetch(`/api/invoices/paginated/${offset * 12}`, {
      method: "get",
      credentials: "include",
    })
      .then((res) => {
        if (res.ok) return res.json();
      })
      .then((data) => {
        setLoading(false);
        setData(data);
      })
      .catch((error) => {
        setLoading(false);
      });
  }, [refresh]);

  function quickSearch(value) {
    setData(null);
    setLoading(true);
    fetch(`/api/mobile/quicksearch/${value}`, {
      method: "get",
      credentials: "include",
    })
      .then((res) => {
        if (res.ok) return res.json();
      })
      .then((data) => {
        setLoading(false);
        setData(data);
      })
      .catch((error) => {
        setLoading(false);
      });
  }

  function withCommas(x) {
    return x?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  return (
    <div className="invoicing">
      <div className="welcome">
        <div>
          <h1>Welcome Back, {user}</h1>
        </div>
        <FiRefreshCcw
          onClick={() => {
            setRefresh(!refresh);
          }}
          className="refresh"
        />
      </div>

      <div className="taskstats">
        <div className="left">
          <div className="bar">
            <div></div>
            <h2>Invoices</h2>
          </div>
          <div className="outer">
            <div className="ll">
              <div className="section">
                <div className="single">
                  <FaFileInvoiceDollar className="ts" />
                  <div>
                    <h4>{sdata ? withCommas(sdata?.AllTasks) : 0}</h4>
                    <p>Total Invoices</p>
                  </div>
                </div>
              </div>
              <div className="section">
                <div className="gauge">
                  <h3>Revenue Collection Rate</h3>
                  <GaugeChart
                    className="gg"
                    id="gauge-chart2"
                    nrOfLevels={3}
                    colors={["red", "orange", "green"]}
                    arcWidth={0.4}
                    percent={
                      sdata ? (sdata?.Collected / sdata?.Billed).toFixed(2) : 0
                    }
                    textColor="gray"
                  />
                </div>
              </div>
            </div>
            <div className="section">
              <h3>Monthly Performance</h3>
              <CustomBarChart
                aspect={1.8}
                data={sdata ? sdata.Bar : null}
                color="orange"
              />
            </div>
          </div>
        </div>
        <div className="right">
          <div className="bar">
            <div></div>
            <h2>Revenue Collection</h2>
          </div>
          <div className="outer">
            <div className="section">
              <div className="perf">
                <div className="hl">
                  <h4>
                    KSh{" "}
                    {sdata
                      ? (sdata?.Collected - 0).toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })
                      : "0.00"}
                  </h4>
                  <h5>Total Collected</h5>
                </div>

                <div className="div1auto">
                  <div style={{ backgroundColor: "orange" }}></div>
                  <p>Total Billed: KSh </p>
                  <h6>
                    {sdata
                      ? (sdata?.Billed - 0).toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })
                      : 0}
                  </h6>
                </div>
                <div className="div1auto">
                  <div style={{ backgroundColor: "green" }}></div>
                  <p>Collected: KSh</p>
                  <h6>
                    {sdata
                      ? (sdata?.Collected - 0).toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })
                      : "0.00"}
                  </h6>
                </div>
                <div className="div1auto">
                  <div style={{ backgroundColor: "red" }}></div>
                  <p>Pending: KSh </p>
                  <h6>
                    {sdata
                      ? (sdata?.Billed - sdata?.Collected).toLocaleString(
                          undefined,
                          {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          }
                        )
                      : "0.00"}
                  </h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
      <div className="list">
        <div className="utp">
          <h3>My Invoices</h3>
          <p
            onClick={() => {
              window.location.href = "/invoices/new";
            }}
          >
            <FontAwesomeIcon className="fa-add" icon={faUserPlus} /> New Invoice
          </p>
          <div className="search">
            <input
              type="text"
              name="search"
              id="search"
              placeholder="Name..."
              onChange={(e) => {
                const v = e.target.value;
                if (v != "") {
                  quickSearch(v);
                } else {
                  setRefresh(!refresh);
                }
              }}
            />
            <FontAwesomeIcon className="fa-search" icon={faSearch} />
          </div>
        </div>
        <hr />

        <div>
          <div className="lcontainer">
            <div className="user-list">
              {data &&
                data?.data?.length > 0 &&
                data?.data?.map((item, index) => {
                  return (
                    <InvoiceItem
                      key={index}
                      item={item}
                      userID={userID}
                      setUserID={setUserID}
                    />
                  );
                })}
            </div>
          </div>
          {data && (
            <Pagination
              totalPages={Math.ceil(data?.total / 12)}
              currentPage={offset}
              handlePageChange={(v) => {
                setOffset(v);
              }}
            />
          )}
        </div>

        {loading && <Loading />}
      </div>
    </div>
  );
}

const TopItem = (props) => {
  const [amt, setAmt] = useState(0);

  useEffect(() => {
    setAmt(props.amt);
  }, [props.amt]);

  function withCommas(x) {
    return x?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  return (
    <div style={{ backgroundImage: `url(${props.img})` }} className="tp_item">
      <h4>{props.title}</h4>
      <h3>{withCommas(amt)}</h3>
    </div>
  );
};

const InvoiceItem = (props) => {
  const [color, setColor] = useState("orange");

  function withCommas(x) {
    return x?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  useEffect(() => {
    if (props?.item?.Total === props?.item?.Balance) {
      setColor("orange");
    } else {
      setColor("green");
    }
  }, [props.item]);

  return (
    <div
      className={
        props.userID === props?.item?.UserID
          ? "customer-box active"
          : "customer-box"
      }
      onClick={() => {
        window.location.href = "/invoices/preview/" + props?.item?.ID;
      }}
    >
      <div className="left">
        <h2
          style={{
            backgroundColor: color,
          }}
        >
          <span>KSh {withCommas(props?.item?.Total)}</span>
        </h2>
      </div>
      <div className="right">
        <h3 className="title">{props?.item?.Name}</h3>
        <p className="dark">
          <FontAwesomeIcon
            style={{ marginRight: "10px" }}
            color="#0064B6"
            icon={faEnvelope}
          />
          {props?.item?.Email}
        </p>
        <p className="dark">
          <FontAwesomeIcon
            style={{ marginRight: "10px" }}
            color="#0064B6"
            icon={faPhone}
          />
          {props?.item?.Phone}
        </p>
        <p className="calendar">{props?.item?.Date}</p>
        <div className="status">Sent</div>
      </div>

      <div className="bal">
        <p>Balance: KSh {withCommas(props?.item?.Balance)}</p>
      </div>
    </div>
  );
};

import { useState, useEffect, useRef } from "react";
import { Watermark } from "@hirohe/react-watermark";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAddressBook,
  faArrowLeftLong,
  faDownload,
  faEnvelope,
  faMailBulk,
  faMailReply,
  faMoneyBill,
  faPhone,
  faTimes,
  faUserAlt,
  faUserPlus,
} from "@fortawesome/free-solid-svg-icons";
import Loading from "../Util/Loading";
import logo from "../../assets/images/logo.png";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import Input from "../Util/Input";

export default function PreviewInvoice(props) {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const [paid, setPaid] = useState(0);
  const [payments, setPayments] = useState(null);
  const [currency, setCurrency] = useState("KSh");
  const [customer, setCustomer] = useState(null);
  const [bdata, setBdata] = useState([]);
  const [pbody, setPBody] = useState({
    InvoiceID: null,
    Customer: null,
    Date: null,
    Amount: null,
  });
  const [user, setUser] = useState(null);
  const pathname = window.location.pathname.split("/");
  const [generated, setGenerated] = useState(true);
  const [refresh, setRefresh] = useState(false);
  const [creating, setCreating] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetch(`/api/invoices/${pathname[3]}`)
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else throw Error();
      })
      .then((data) => {
        setData(data);
        if (data) {
          let d = pbody;
          d.InvoiceID = data.ID;
          setPBody(d);
          setGenerated(data?.Status);
        }
      })
      .catch((e) => {});
  }, [refresh]);

  useEffect(() => {
    if (data) {
      fetch(`/api/invitems/byinvoiceid/${data?.ID}`)
        .then((res) => {
          if (res.ok) {
            return res.json();
          } else throw Error();
        })
        .then((data) => {
          setBdata(data);
        })
        .catch((e) => {});
    }
  }, [data]);

  useEffect(() => {
    if (data) {
      fetch(`/api/mobile/${data?.CustomerID}`)
        .then((res) => {
          if (res.ok) {
            return res.json();
          } else throw Error();
        })
        .then((data) => {
          let d = pbody;
          d.Customer = data?.Name;
          setPBody(d);
          setCustomer(data);
        })
        .catch((e) => {});
    }
  }, [data]);

  useEffect(() => {
    if (data) {
      fetch(`/api/auth/${data?.UserID}`)
        .then((res) => {
          if (res.ok) {
            return res.json();
          } else throw Error();
        })
        .then((data) => {
          setUser(data);
        })
        .catch((e) => {});
    }
  }, [data]);

  useEffect(() => {
    if (data) {
      fetch(`/api/payments/getallbyinvoiceid/${data?.ID}`)
        .then((res) => {
          if (res.ok) {
            return res.json();
          } else throw Error();
        })
        .then((data) => {
          setPayments(data);
          let d = 0;

          data.map((i) => {
            d += parseInt(i.Amount);
          });
          setPaid(d);
        })
        .catch((e) => {});
    }
  }, [data, refresh]);

  const downloadPdfDocument = () => {
    const invoice = document.getElementById("invoice");

    html2canvas(invoice, { scale: 2 }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("p", "mm", "a4");

      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = pdf.internal.pageSize.getHeight();

      // Calculate the aspect ratio
      const aspectRatio = canvas.width / canvas.height;

      // Calculate the image dimensions for the PDF
      let imgWidth = pdfWidth;
      let imgHeight = pdfWidth / aspectRatio;

      // if (imgHeight > pdfHeight) {
      //   imgHeight = pdfHeight;
      //   imgWidth = pdfHeight * aspectRatio;
      // }

      // Center the image in the PDF
      const xOffset = (pdfWidth - imgWidth) / 2;
      const yOffset = 0; // Start from the top for the main content

      pdf.addImage(imgData, "PNG", xOffset, yOffset, imgWidth, imgHeight);

      // Now handle the footer separately
      // Capture the footer separately
      const footer = document.querySelector(".invfooter");

      html2canvas(footer, { scale: 2 }).then((footerCanvas) => {
        const footerImgData = footerCanvas.toDataURL("image/png");

        // Calculate the dimensions of the footer image
        const footerAspectRatio = footerCanvas.width / footerCanvas.height;
        let footerImgWidth = imgWidth;
        let footerImgHeight = imgWidth / footerAspectRatio;

        if (footerImgHeight > pdfHeight) {
          footerImgHeight = pdfHeight;
          footerImgWidth = pdfHeight * footerAspectRatio;
        }

        // Add footer image at the bottom
        const footerYOffset = pdfHeight - footerImgHeight; // Bottom position

        pdf.addImage(
          footerImgData,
          "PNG",
          0,
          footerYOffset,
          footerImgWidth,
          footerImgHeight
        );
        pdf.save(`${customer?.Name}.pdf`);
      });
    });
  };

  const sendPdfDocument = () => {
    const invoice = document.getElementById("invoice");

    html2canvas(invoice, { scale: 2 }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("p", "mm", "a4");

      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = pdf.internal.pageSize.getHeight();

      // Calculate the aspect ratio
      const aspectRatio = canvas.width / canvas.height;

      // Calculate the image dimensions for the PDF
      let imgWidth = pdfWidth;
      let imgHeight = pdfWidth / aspectRatio;

      // if (imgHeight > pdfHeight) {
      //   imgHeight = pdfHeight;
      //   imgWidth = pdfHeight * aspectRatio;
      // }

      // Center the image in the PDF
      const xOffset = (pdfWidth - imgWidth) / 2;
      const yOffset = 0; // Start from the top for the main content

      pdf.addImage(imgData, "PNG", xOffset, yOffset, imgWidth, imgHeight);

      // Now handle the footer separately
      // Capture the footer separately
      const footer = document.querySelector(".invfooter");

      html2canvas(footer, { scale: 2 }).then((footerCanvas) => {
        const footerImgData = footerCanvas.toDataURL("image/png");

        // Calculate the dimensions of the footer image
        const footerAspectRatio = footerCanvas.width / footerCanvas.height;
        let footerImgWidth = imgWidth;
        let footerImgHeight = imgWidth / footerAspectRatio;

        if (footerImgHeight > pdfHeight) {
          footerImgHeight = pdfHeight;
          footerImgWidth = pdfHeight * footerAspectRatio;
        }

        // Add footer image at the bottom
        const footerYOffset = pdfHeight - footerImgHeight; // Bottom position

        pdf.addImage(
          footerImgData,
          "PNG",
          0,
          footerYOffset,
          footerImgWidth,
          footerImgHeight
        );
        const blb = pdf.output(`blob`);
        sendData(blb, `${customer?.Name}`);
      });
    });
  };

  function sendData(pdf, downloadFileName) {
    const formData = new FormData();
    formData.append("Invoice", pdf, `${downloadFileName}.pdf`);
    formData.append("Email", customer?.Email);
    formData.append("Name", customer?.Name);

    fetch(`/api/invoices/sendemail/${data?.ID}`, {
      method: "PUT",
      credentials: "include",
      headers: {
        Accept: "application/json",
      },
      body: formData,
    })
      .then((res) => {
        if (res.ok) return res.json();
        else throw Error("");
      })
      .then((data) => {
        if (data.success) {
          setError(data.success);
          setTimeout(() => {
            setError(null);
          }, 1000);
        } else {
          setError(data.error);
          setTimeout(() => {
            setError(null);
          }, 1000);
        }
      })
      .catch((e) => {});
  }

  return (
    <div className="invoicing">
      <div className="container">
        <div className="banner">
          <h4>{customer?.Name} Invoice</h4>
          <button
            onClick={() => {
              window.location.href = "/invoices";
            }}
          >
            <FontAwesomeIcon icon={faArrowLeftLong} /> Back
          </button>
        </div>
        <div className="divsplit">
          <div className="inv_preview">
            <div id="invoice" className="invoice">
              <div className="bar">
                <div className="invtop">
                  <img src={logo} alt="" />
                  <p>Vedic House, 5th Floor,</p>
                  <p>North wing, Room 509/510,</p>
                  <p>P.O. Box 20252-00100.</p>
                </div>
                <h1>INVOICE</h1>
                <div className="div"></div>
              </div>
              <div className="invheader">
                <div className="left">
                  <div className="enclose">
                    <h4>Invoice to:</h4>
                    <hr />
                    <div className="div1auto">
                      <h4>Name: </h4>
                      <p> {customer?.Name}</p>
                    </div>
                    <div className="div1auto">
                      <h4>LPO No: </h4>
                      <p>{data?.Title}</p>
                    </div>
                  </div>
                </div>
                <div className="right">
                  <div className="div1auto">
                    <h4>Due Date:</h4>
                    <p>{data?.Date}</p>
                  </div>
                  <div className="div1auto">
                    <h4>Invoice No.:</h4>
                    <p>{data?.InvoiceNo}</p>
                  </div>
                  <div className="div1auto">
                    <h4>PIN No.:</h4>
                    <p>P051588439V</p>
                  </div>
                  <div className="div1auto">
                    <h4>Prepared By:</h4>
                    <p>{user?.Name}</p>
                  </div>
                  <div className="div1auto">
                    <h4>Date Prepared:</h4>
                    <p>{data?.createdAt.split("T")[0]}</p>
                  </div>
                </div>
              </div>
              <div className="section">
                <h4>Terms: Max credit period per invoice 1 month</h4>
                <div className="tphead">
                  <h3>Description</h3>
                  <h3>Quantity</h3>
                  <h3>Rate (KSh)</h3>
                  <h3>Total (KSh)</h3>
                </div>
                {bdata &&
                  bdata.map((item, i) => {
                    return (
                      <BillingItem
                        bdata={bdata}
                        setBdata={setBdata}
                        key={i}
                        index={i}
                        item={item}
                      />
                    );
                  })}
              </div>
              <div className="totals">
                <div className="div1auto">
                  <div>
                    <h4>Thank you for your business</h4>

                    <p>
                      This is a system generated invoice. No signature or stamp
                      is required.
                    </p>
                  </div>
                  <div>
                    <div className="tdiv">
                      <h4>Sub Total ({currency}):</h4>{" "}
                      <p>
                        {" "}
                        {data &&
                          (data?.SubTotal - 0).toLocaleString(undefined, {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}
                      </p>
                    </div>

                    <div className="tdiv">
                      <h4>
                        16% VAT (
                        {customer?.Currency ? customer?.Currency : "KSh"}
                        ):
                      </h4>{" "}
                      <p>
                        {" "}
                        {data &&
                          (data?.VAT - 0).toLocaleString(undefined, {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}
                      </p>
                    </div>
                    <div className="tdiv tt">
                      <h4>
                        Total ({customer?.Currency ? customer?.Currency : "KSh"}
                        ):
                      </h4>{" "}
                      <p>
                        {" "}
                        {data &&
                          (data?.Total - 0).toLocaleString(undefined, {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="payment">
                <h4>Payment Details</h4>
                <div className="div2equal">
                  <div>
                    <h4>Bank Transfer/Cheque</h4>
                    <p>A/C Name: Arope Group Limited</p>
                    <p>Account #: 1200515226</p>
                    <p>SWIFT Code: KCBLKENX</p>
                    <p>Bank: KCB Bank, Karen; Bank Code: 1</p>
                  </div>
                  <div>
                    <h4>M-PESA Paybill </h4>
                    <p>A/C Name: Arope Group Limited</p>
                    <p>Business #: 709 777</p>
                    <p>Account #: {"{Client Name}"}</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="invfooter">
              <div className="fbars">
                <div></div>
                <h6>Arope Group LTD</h6>
                <div></div>
              </div>
              <div className="contacts">
                <p>
                  <FontAwesomeIcon icon={faPhone} /> +254 754 709 777
                </p>
                <p>
                  <FontAwesomeIcon icon={faPhone} />
                  +254 726 709 777
                </p>
                <p>
                  <FontAwesomeIcon icon={faAddressBook} /> info@aropegroup.com
                </p>
              </div>
            </div>
            {!generated && (
              <Watermark
                wrapperStyle={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  bottom: 0,
                  right: 0,
                  zIndex: 999,
                }}
                text={`This is a preview invoice`}
              ></Watermark>
            )}
          </div>
          <div className="iright_panel">
            <div className="links">
              <button
                onClick={() => {
                  sendPdfDocument();
                }}
              >
                <FontAwesomeIcon className="ic" icon={faEnvelope} />
                {data && data.Invoice ? "Send Again" : "Send Invoice"}
              </button>
              {error && <h6>{error}</h6>}
              <button
                onClick={() => {
                  downloadPdfDocument();
                }}
              >
                <FontAwesomeIcon className="ic" icon={faDownload} /> Download
                Invoice
              </button>
              <button
                onClick={() => {
                  setCreating(true);
                }}
              >
                <FontAwesomeIcon className="ic" icon={faMoneyBill} /> Add
                Payment
              </button>
            </div>

            <div className="inv_stats">
              <div className="tp">
                <div className="lf">
                  <p>Paid</p>
                  <h4>
                    {paid.toLocaleString(undefined, {
                      maximumFractionDigits: 2,
                      minimumFractionDigits: 2,
                    })}
                  </h4>
                </div>
                <div className="rf">
                  <p>Currency</p>
                  <p>KSh</p>
                </div>
              </div>
              <div className="div2equal">
                <p>Balance</p>
                <h5>
                  {data
                    ? (data.Total - paid).toLocaleString(undefined, {
                        maximumFractionDigits: 2,
                        minimumFractionDigits: 2,
                      })
                    : "0.00"}
                </h5>
              </div>
            </div>
            <div className="c_payments">
              <h3>Payments</h3>
              <hr />
              <div>
                {payments &&
                  payments.length > 0 &&
                  payments.map((item, i) => {
                    return (
                      <PaymentItem
                        refresh={refresh}
                        setRefresh={setRefresh}
                        setLoading={setLoading}
                        key={i}
                        item={item}
                        index={i}
                      />
                    );
                  })}
              </div>
              {creating && (
                <CreatePayment
                  body={pbody}
                  setCreating={setCreating}
                  refresh={refresh}
                  setRefresh={setRefresh}
                  setLoading={setLoading}
                />
              )}
            </div>
          </div>
        </div>

        {!generated && (
          <CreateInvoice
            refresh={refresh}
            setRefresh={setRefresh}
            setLoading={setLoading}
            id={pathname[3]}
          />
        )}
        {loading && <Loading />}
      </div>
    </div>
  );
}

const BillingItem = (props) => {
  return (
    <div className="tprow">
      <h2>{props?.item?.Item}</h2>
      <h2>{props?.item?.Qty}</h2>
      <h2>
        {(props?.item?.Rate - 0).toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })}
      </h2>
      <h2>
        {(props?.item?.Rate * props?.item?.Qty).toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })}
      </h2>
    </div>
  );
};

const PaymentItem = (props) => {
  function deleteItem() {
    fetch(`/api/payments/${props.item.ID}`, {
      method: "DELETE",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((res) => {
        if (res.ok) return res.json();
        else throw Error("");
      })
      .then((data) => {
        props.setCreating(false);
        props.setRefresh(!props.refresh);
        props.setLoading(false);
      })
      .catch((e) => {
        props.setLoading(false);
      });
  }
  return (
    <div className="pitem">
      <div className="no">{props.index + 1}</div>
      <div>
        <h4>{props?.item?.Customer}</h4>
        <p>{props?.item?.Date}</p>
      </div>

      <h5>
        KSh{" "}
        {(props?.item?.Amount - 0).toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })}
      </h5>
      <FontAwesomeIcon
        onClick={() => {
          deleteItem();
        }}
        className="fa-times"
        icon={faTimes}
      />
    </div>
  );
};

const CreateInvoice = (props) => {
  function createInv() {
    props.setLoading(true);
    fetch(`/api/invoices/${props.id}`, {
      method: "PUT",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({ Status: true }),
    })
      .then((res) => {
        if (res.ok) return res.json();
        else throw Error("");
      })
      .then((data) => {
        props.setRefresh(!props.refresh);
        props.setLoading(false);
      })
      .catch((e) => {
        props.setLoading(false);
      });
  }

  return (
    <div className="createinv">
      <div className="wrap">
        <h1>Generate Invoice</h1>
        <p>
          You must generate an invoice before you can download or send the
          invoice
        </p>
        <button
          onClick={() => {
            createInv();
          }}
        >
          Accept
        </button>
        <h4
          onClick={() => {
            window.location.href = "/invoices";
          }}
        >
          Cancel
        </h4>
      </div>
    </div>
  );
};

const CreatePayment = (props) => {
  const [body, setBody] = useState(props.body);
  const date = useRef();
  const amount = useRef();

  function createInv() {
    if (date.current.value == "" || amount.current.value == "") return;
    let d = body;
    d.Date = date.current.value;
    d.Amount = amount.current.value;

    props.setLoading(true);
    fetch(`/api/payments/create`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(d),
    })
      .then((res) => {
        if (res.ok) return res.json();
        else throw Error("");
      })
      .then((data) => {
        props.setCreating(false);
        props.setRefresh(!props.refresh);
        props.setLoading(false);
      })
      .catch((e) => {
        props.setLoading(false);
      });
  }

  return (
    <div className="createinv">
      <div className="wrap">
        <h1>Create Payment</h1>
        <hr />
        <br />
        <Input
          ref={date}
          label="Date"
          type="date"
          value={new Date().toISOString().split("T")[0]}
        />
        <Input ref={amount} label="Amount" type="number" />
        <button
          onClick={() => {
            createInv();
          }}
        >
          Accept
        </button>
        <FontAwesomeIcon
          icon={faTimes}
          className="fa-times"
          onClick={() => {
            props.setCreating(false);
          }}
        />
      </div>
    </div>
  );
};

import { useEffect, useRef, useState } from "react";
import "../../Styles/singletask.scss";
import WaveLoading from "../Util/WaveLoading";
import { BsInfoSquare } from "react-icons/bs";
import { GrInProgress } from "react-icons/gr";
import { BsFillPersonCheckFill } from "react-icons/bs";
import CreateReport from "./CreateReport";
import { useLocation } from "react-router-dom";
import Select from "../Util/Select";
import Input from "../Util/Input";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faTimesCircle } from "@fortawesome/free-solid-svg-icons";

export default function SingleTask(props) {
  const [isLoading, setIsLoading] = useState(null);
  const [data, setData] = useState(null);

  const [refresh, setRefresh] = useState(false);
  const [jdets, setJDets] = useState(null);
  const [clicked, setClicked] = useState(null);
  const [udets, setUDets] = useState(null);
  const [tt, setTT] = useState(null);
  const [ct, setCT] = useState(null);
  const location = useLocation();
  const id = location.pathname.split("/")[2];

  useEffect(() => {
    setIsLoading(true);

    fetch(`/api/requests/${id}`)
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else throw Error("");
      })
      .then((data) => {
        setIsLoading(false);
        setJDets(data);
      })
      .catch((e) => {
        setIsLoading(false);
      });
  }, [refresh]);

  useEffect(() => {
    if (jdets) {
      setIsLoading(true);
      fetch(`/api/mobile/${jdets?.UserID}`)
        .then((res) => {
          if (res.ok) {
            return res.json();
          } else throw Error("");
        })
        .then((data) => {
          setIsLoading(false);
          setUDets(data);
        })
        .catch((e) => {
          setIsLoading(false);
        });
    }
  }, [jdets]);

  useEffect(() => {
    setData(null);
    setIsLoading(true);
    fetch(`/api/tasks/requestid/${id}`)
      .then((res) => {
        if (res.ok) return res.json();
        else throw Error();
      })
      .then((data) => {
        if (data.length > 0) {
          setData(data[0]);
        }
        setIsLoading(false);
      })
      .catch((e) => {
        setIsLoading(false);
      });
  }, [refresh]);

  useEffect(() => {
    setIsLoading(true);
    fetch(`/api/subjects/checks/byjobid/${id}`)
      .then((res) => {
        if (res.ok) return res.json();
        else throw Error();
      })
      .then((data) => {
        if (data.length > 0) {
          let total = 0;
          let count = 0;
          data.map((item) => {
            const it = JSON.parse(item.Checks);
            total = total + it.length;
            it.map((i) => {
              if (!i.Status) {
                count += 1;
              }
            });
          });
          setTT(total);
          setCT(count);
        }
        setIsLoading(false);
      })
      .catch((e) => {
        setIsLoading(false);
      });
  }, [refresh]);

  useEffect(() => {
    if (data && data.Checking === null) {
      fetch(`/api/tasks/${data.ID}`, {
        method: "PUT",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({ Checking: false }),
      })
        .then((res) => {
          if (res.ok) return res.json();
          else throw Error();
        })
        .then((data) => {
          setRefresh(!refresh);
          setIsLoading(false);
        })
        .catch((e) => {
          setIsLoading(false);
        });
    }
  }, [data]);

  useEffect(() => {
    if (data) {
      if (ct === 0 && tt !== 0) {
        updateStatus(true, false);
      }
    }
  }, [tt, ct]);

  function updateStatus(checking, report) {
    setIsLoading(true);
    fetch(`/api/tasks/${data.ID}`, {
      method: "PUT",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({ Checking: checking, ReportPreparation: report }),
    })
      .then((res) => {
        if (res.ok) return res.json();
        else throw Error();
      })
      .then((data) => {
        setRefresh(!refresh);
        setIsLoading(false);
      })
      .catch((e) => {
        setIsLoading(false);
      });
  }

  return (
    <>
      <div data-aos="fade-left" className="singletask">
        <div className="pcontainer">
          <div className="wcontent">
            <div className="pdiv">
              <h5>
                <BsInfoSquare /> Basic Info
              </h5>
              <div className="details div3equal">
                <div className="u_list">
                  <h3>Job Details</h3>
                  <p>
                    <b>Title: </b> {jdets && jdets?.Title}
                  </p>
                  <p>
                    <b>Description: </b> {jdets && jdets?.Description}
                  </p>
                  <p>
                    <b>Consent Form: </b>{" "}
                    {jdets &&
                      jdets?.Consent?.substring(13, jdets?.Consent?.length)}
                  </p>
                  <p>
                    <b>No of Subjects: </b>
                    {/* {props.item.Clients ? props.item.Clients.length : 0} */}
                  </p>
                </div>
                <div className="u_list">
                  <h3>Client Details</h3>
                  <p>
                    <b>Name: </b> {udets && udets?.Name}
                  </p>
                  <p>
                    <b>Phone: </b> {udets && udets?.Phone}
                  </p>
                  <p>
                    <b>Email: </b> {udets && udets?.Email}
                  </p>
                  <p>
                    <b>Organisation: </b> {udets && udets?.Organisation}
                  </p>
                </div>
                <div className="div prg u_list">
                  <h3>Progress</h3>
                  <Item txt="Received" status={true} />
                  <Item
                    txt="Assigned to Staff"
                    status={data ? data.Received : null}
                  />
                  <Item
                    txt="Actual Checks"
                    status={data ? data.Checking : null}
                    tally={
                      tt && ct
                        ? ` ${tt - ct}/${tt} --- ${(
                            ((tt - ct) / tt) *
                            100
                          ).toFixed(0)}%`
                        : null
                    }
                  />

                  <Item
                    txt="Report Submission"
                    status={data ? data.ReportSubmitted : null}
                  />
                </div>
              </div>
            </div>

            <div className="pdiv">
              <SubjectsPopup
                JobID={id}
                UserID={udets?.UserID}
                RequestID={jdets?.DocumentID}
              />
            </div>

            {isLoading && <WaveLoading />}
          </div>
        </div>
      </div>
    </>
  );
}

const Item = (props) => {
  const [color, setColor] = useState("red");

  useEffect(() => {
    switch (props.status) {
      case true:
        setColor("green");
        break;
      case false:
        setColor("orange");
        break;
      case null:
        setColor("red");
        break;
      default:
        setColor("red");
        break;
    }
  }, [props.status]);

  return (
    <div className="item">
      <div style={{ backgroundColor: color }}></div>
      <p>
        {props.txt} <b>{props.tally ? props.tally : ""}</b>
      </p>
    </div>
  );
};

const SubjectsPopup = (props) => {
  const [isLoading, setIsLoading] = useState(null);
  const [data, setData] = useState(null);
  const [active, setActive] = useState(0);
  const [refresh, setRefresh] = useState(false);
  const [showing, setShowing] = useState(null);
  const [report, setReport] = useState(null);

  useEffect(() => {
    setIsLoading(true);
    fetch(`/api/subjects/byjobid/${props.JobID}`)
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else throw Error("");
      })
      .then((data) => {
        setIsLoading(false);

        if (data.length > 0) {
          const y = data;
          let d = [];
          y.map((item) => {
            let x = item;
            x.Checks = JSON.parse(item.Checks);
            d.push(x);
          });
          setData(d);

          let status = true;
          d[active].Checks.map((e) => {
            if (e.Status.toString() === "false") {
              status = false;
            }
          });

          if (status) {
            setReport(true);
          } else setReport(null);
        }
      })
      .catch((e) => {
        setIsLoading(false);
      });
  }, [props.JobID, active, refresh]);

  const UserList = (params) => {
    return (
      <div
        onClick={() => {
          setActive(params.index);
        }}
        style={{
          backgroundColor: active === params.index ? "#FFF5B8" : "white",
        }}
        className="u_list"
      >
        <div className="no">
          <p>{params.index + 1}</p>
        </div>

        <div className="div2equal">
          <p>
            <b>Name: </b>
            {params.item.Name}
          </p>
          <p>
            <b>ID/Passport: </b>
            {params.item.IDNo}
          </p>
        </div>
      </div>
    );
  };

  const Link = (params) => {
    const txt = params?.link?.slice(13);

    function getDocument() {
      setIsLoading(true);
      fetch(`/api/uploads/${params.link}`)
        .then((res) => {
          if (res.ok) {
            return res.blob();
          } else throw Error("");
        })
        .then((blob) => {
          const url = window.URL.createObjectURL(blob);
          window.open(url, "_blank");
          setIsLoading(false);
        })
        .catch((e) => {
          alert("File not found!");
          setIsLoading(false);
        });
    }

    return (
      <p
        onClick={() => {
          getDocument();
        }}
        className="link"
      >
        {params.index + 1}. {txt}
      </p>
    );
  };

  const SingleCheck = (params) => {
    const [cliked, setClicked] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [blob, setBlob] = useState(null);

    useEffect(() => {
      if (params.item.Status.toString() !== "false") {
        fetch(`/api/uploads/${params.item.Status}`)
          .then((res) => {
            if (res.ok) return res.blob();
            else throw Error("");
          })
          .then((blob) => {
            const url = window.URL.createObjectURL(blob);
            setBlob(url);
          })
          .catch((e) => {});
      }
    }, [cliked]);

    const UpdateStatus = (prp) => {
      const sf = useRef();
      const [error, setError] = useState("");
      const [current, setCurrent] = useState("");

      useEffect(() => {
        if (
          prp.item.Name === "Previous Employment Check" ||
          prp.item.Name === "Educational Check" ||
          prp.item.Name === "Referees Check"
        ) {
          setCurrent("Email");
        } else setCurrent("Report");
      }, []);

      useEffect(() => {
        fetch(`/api/emails/bycheck/${prp.item.Name}/${data[active].IDNo}`)
          .then((res) => {
            if (res.ok) return res.json();
            else throw Error("");
          })
          .then((data) => {})
          .catch((e) => {});
      }, [refresh]);

      function uploadReport() {
        if (sf.current.files.length === 0)
          return setError("This report is required!");
        const formData = new FormData();
        formData.append(`Reports`, sf.current.files[0]);
        formData.append(`Name`, prp.item.Name);
        setIsLoading(false);
        fetch(`/api/subjects/status/${params.id}`, {
          method: "PUT",
          credentials: "include",
          headers: {
            Accept: "application/json",
          },
          body: formData,
        })
          .then((response) => {
            if (response.ok) {
              return response.json();
            } else throw Error("");
          })
          .then((data) => {
            setIsLoading(false);
            if (data.success) {
              setError(data.success);
              setTimeout(() => {
                params.setRefresh(!params.refresh);
                prp.setClicked(false);
              }, 500);
            } else {
              setError(data.error);
              setTimeout(() => {
                params.setRefresh(!params.refresh);
                prp.setClicked(false);
              }, 500);
            }
          })
          .catch((err) => {
            setIsLoading(false);
            setError("Oops! Something went wrong!");
            setTimeout(() => {
              setClicked(false);
            }, 500);
          });
      }

      const Bar = (params) => {
        return (
          <p
            className={params.txt === current ? "active" : ""}
            onClick={() => {
              setCurrent(params.txt);
            }}
          >
            {params.txt}
          </p>
        );
      };

      return (
        <div className="updateprp">
          <div className="up_wrapper">
            <div className="up_bar">
              {(prp.item.Name === "Previous Employment Check" ||
                prp.item.Name === "Educational Check" ||
                prp.item.Name === "Referees Check") && <Bar txt="Email" />}
              {(prp.item.Name === "Previous Employment Check" ||
                prp.item.Name === "Educational Check" ||
                prp.item.Name === "Referees Check") && (
                <Bar txt="Sent Emails" />
              )}
              <Bar txt="Report" />
            </div>
            <i
              onClick={() => {
                prp.setClicked(false);
              }}
              className="fa fa-times"
            ></i>

            {current === "Report" && (
              <div className="report">
                <Select label="Report Type" data={["File", "Comment"]} />
                <div className="input-container">
                  <input ref={sf} type="file" label="Upload file *" />
                </div>
                <h6>{error}</h6>
                <button
                  onClick={() => {
                    uploadReport();
                  }}
                >
                  Submit
                </button>
              </div>
            )}
            {current === "Sent Emails" && <div className="emails"></div>}
            {current === "Email" && data && (
              <>
                {prp.item.Name === "Previous Employment Check" && (
                  <EmploymentEmail
                    type={prp.item.Name}
                    Name={data[active].Name}
                    NationalID={data[active].IDNo}
                    setClicked={prp.setClicked}
                  />
                )}
                {prp.item.Name === "Educational Check" && (
                  <EducationEmail
                    type={prp.item.Name}
                    Name={data[active].Name}
                    Attachment={data[active].SubjectFiles}
                    NationalID={data[active].IDNo}
                    setClicked={prp.setClicked}
                  />
                )}
                {prp.item.Name === "Referees Check" && (
                  <ReferenceEmail
                    type={prp.item.Name}
                    Name={data[active].Name}
                    NationalID={data[active].IDNo}
                    setClicked={prp.setClicked}
                  />
                )}
              </>
            )}
          </div>
          {isLoading && <WaveLoading />}
        </div>
      );
    };

    function deleteReport() {
      const formData = new FormData();
      formData.append(`Name`, params.item.Name);
      setIsLoading(false);
      fetch(`/api/subjects/status/${params.id}`, {
        method: "PUT",
        credentials: "include",
        headers: {
          Accept: "application/json",
        },
        body: formData,
      })
        .then((response) => {
          if (response.ok) {
            return response.json();
          } else throw Error("");
        })
        .then((data) => {
          setIsLoading(false);
          if (data.success) {
            setTimeout(() => {
              setRefresh(!refresh);
            }, 500);
          } else {
            setTimeout(() => {
              setRefresh(!refresh);
            }, 500);
          }
        })
        .catch((err) => {
          setIsLoading(false);
          setTimeout(() => {
            setClicked(false);
          }, 500);
        });
    }

    return (
      <>
        <div className="p_check">
          <div
            style={{ backgroundColor: params.item.Status ? "green" : "red" }}
          ></div>
          <p>{params.item.Name}</p>
          <FontAwesomeIcon
            onClick={() => {
              if (params.item.Status.toString() === "false") {
                setClicked(true);
              } else {
                if (blob) window.open(blob, "_blank");
                else alert("File not found!");
              }
            }}
            className="ic-eye"
            icon={faEye}
          />
          <FontAwesomeIcon
            onClick={() => {
              deleteReport();
            }}
            className="ic-times"
            icon={faTimesCircle}
          />
        </div>
        {cliked && <UpdateStatus item={params.item} setClicked={setClicked} />}
      </>
    );
  };

  function getReport(link) {
    setIsLoading(true);
    fetch(`/api/uploads/${link}`)
      .then((res) => {
        if (res.ok) {
          return res.blob();
        } else throw Error("");
      })
      .then((blob) => {
        setIsLoading(false);
        const url = window.URL.createObjectURL(blob);
        window.open(url, "_blank");
      })
      .catch((e) => {
        setIsLoading(false);
        alert("File not found!");
      });
  }

  return (
    <div data-aos="fade-left" className="tasks">
      <div className="pcontainer">
        <div className="wcontent">
          <div className="div2equal">
            <div className="u_list">
              <h5>
                <BsInfoSquare /> Subject(s) List
              </h5>
              <div className="details">
                {data &&
                  data.length > 0 &&
                  data.map((item, i) => {
                    return <UserList key={i} item={item} index={i} />;
                  })}
              </div>
            </div>
            <div className="u_list">
              <h5>
                <GrInProgress /> Subject Details
              </h5>
              {data && data.length > 0 && (
                <div>
                  <h5>Basic Details</h5>
                  <p>
                    <b>Name: </b>
                    {data[active].Name}
                  </p>
                  <p>
                    <b>Phone: </b>
                    {data[active].Phone}
                  </p>
                  <p>
                    <b>ID/Passport No.: </b>
                    {data[active].IDNo}
                  </p>
                  <br />
                  <h5>Documents</h5>
                  <hr />
                  <p>
                    <b>National ID / Passport</b>
                  </p>
                  <Link index={0} link={data[active].NationalID} />
                  <p>
                    <b>Other Documents</b>
                  </p>
                  {data[active].SubjectFiles.map((item, i) => {
                    return <Link key={i} index={i} link={item} />;
                  })}
                  <br />
                  <h5>Checks</h5>
                  {data[active]?.Checks?.map((item, i) => {
                    return (
                      <SingleCheck
                        key={i}
                        index={i}
                        item={item}
                        id={data[active].ID}
                        setRefresh={setRefresh}
                        refresh={refresh}
                      />
                    );
                  })}
                </div>
              )}
              <div className="pdiv">
                {data && (
                  <div>
                    {data[active].Report != null ? (
                      <div className="u_list">
                        <p>
                          Report generated on:{" "}
                          {data[active].updatedAt.split("T")[0]}{" "}
                          {data[active].updatedAt.split("T")[1].substring(0, 5)}
                        </p>

                        <button
                          onClick={() => {
                            getReport(data[active].Report);
                          }}
                        >
                          View Report
                        </button>
                      </div>
                    ) : (
                      <div>
                        {report ? (
                          <>
                            <div className="u_list">
                              <h4>System Generated Report</h4>
                              <button
                                onClick={() => {
                                  window.location.href = `/createreport/${data[active].ID}`;
                                }}
                                style={{ backgroundColor: "#281e58" }}
                              >
                                Create Report
                              </button>
                            </div>
                            <div className="u_list">
                              <h4>Regular Report</h4>
                              <button
                                onClick={() => {
                                  setShowing(true);
                                }}
                              >
                                Submit Report
                              </button>
                            </div>
                          </>
                        ) : (
                          <p>Background checking is not complete</p>
                        )}
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>

          {isLoading && <WaveLoading />}
        </div>
      </div>
      {showing && (
        <CreateReport
          refresh={refresh}
          setRefresh={setRefresh}
          setShowing={setShowing}
          SubjectID={data[active].ID}
          RequestID={props.RequestID}
          UserID={props.UserID}
        />
      )}
    </div>
  );
};

const EmploymentEmail = (props) => {
  const [error, setError] = useState("");
  const [loading, setIsLoading] = useState(null);
  const ps = useRef();
  const cn = useRef();
  const dt = useRef();
  const em = useRef();

  function sendEmail() {
    let d = {
      Type: "Employment",
      Email: em.current.value,
      CName: cn.current.value,
      Name: props.Name,
      ID: props.NationalID,
      Position: ps.current.value,
      Date: dt.current.value,
    };
    const chck = Object.values(d);
    let rr = true;

    chck.map((item) => {
      if (item === "" || item === null) {
        rr = false;
        setError("All fields are required!");
      }
    });
    if (!rr) return;
    setError("");
    setIsLoading(true);

    fetch(`/api/subjects/sendemail/${props.id}`, {
      method: "POST",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(d),
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else throw Error("");
      })
      .then((data) => {
        setIsLoading(false);
        if (data.success) {
          setError(data.success);
          setTimeout(() => {
            props.setClicked(false);
          }, 500);
        } else {
          setError(data.error);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        setError("Oops! Something went wrong!");
      });
  }
  return (
    <div className="report">
      <Input ref={em} label="Email Address" />
      <Input ref={cn} label="Company Name" />
      <Input ref={ps} label="Position" />
      <Input ref={dt} label="Date" />
      <h6>{error}</h6>
      <button
        onClick={() => {
          sendEmail();
        }}
      >
        Submit
      </button>
      {loading && <WaveLoading />}
    </div>
  );
};

const EducationEmail = (props) => {
  const [error, setError] = useState("");
  const [loading, setIsLoading] = useState(null);
  const [attachment, setAttachment] = useState(props.Attachment[0]);
  const inst = useRef();
  const ard = useRef();
  const cls = useRef();
  const dt = useRef();
  const em = useRef();
  const cno = useRef();

  function sendEmail() {
    let d = {
      Type: "Education",
      Email: em.current.value,
      Institution: inst.current.value,
      Award: ard.current.value,
      Class: cls.current.value,
      Name: props.Name,
      Attachment: attachment,
      Date: dt.current.value,
      CertificateNo: cno.current.value,
    };
    const chck = Object.values(d);
    let rr = true;

    chck.map((item) => {
      if (item === "" || item === null) {
        rr = false;
        setError("All fields are required!");
      }
    });
    if (!rr) return;
    setError("");
    setIsLoading(true);

    fetch(`/api/subjects/sendemail/${props.id}`, {
      method: "POST",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(d),
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else throw Error("");
      })
      .then((data) => {
        setIsLoading(false);
        if (data.success) {
          setError(data.success);
          setTimeout(() => {
            props.setClicked(false);
          }, 500);
        } else {
          setError(data.error);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        setError("Oops! Something went wrong!");
      });
  }
  return (
    <div className="report">
      <Input ref={em} label="Email Address" />
      <Input ref={inst} label="Institution Name" />
      <Input ref={ard} label="Award Degree/Diploma/Certificate" />
      <Input ref={cls} label="Class/Performance" />
      <Input ref={dt} label="Date" />
      <Input ref={cno} label="Certificate Number" />
      <Select
        data={props.Attachment}
        setChanged={(v) => {
          setAttachment(v);
        }}
        label="Select Certificate"
      />
      <h6>{error}</h6>
      <button
        onClick={() => {
          sendEmail();
        }}
      >
        Submit
      </button>
      {loading && <WaveLoading />}
    </div>
  );
};

const ReferenceEmail = (props) => {
  const [error, setError] = useState("");
  const [loading, setIsLoading] = useState(null);
  const rf = useRef();
  const em = useRef();

  function sendEmail() {
    let d = {
      Type: "Reference",
      Email: em.current.value,
      Referee: rf.current.value,
      Name: props.Name,
    };
    const chck = Object.values(d);
    let rr = true;

    chck.map((item) => {
      if (item === "" || item === null) {
        rr = false;
        setError("All fields are required!");
      }
    });
    if (!rr) return;
    setError("");
    setIsLoading(true);

    fetch(`/api/subjects/sendemail/${props.id}`, {
      method: "POST",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(d),
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else throw Error("");
      })
      .then((data) => {
        setIsLoading(false);
        if (data.success) {
          setError(data.success);
          setTimeout(() => {
            props.setClicked(false);
          }, 2000);
        } else {
          setError(data.error);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        setError("Oops! Something went wrong!");
      });
  }
  return (
    <div className="report">
      <Input ref={em} label="Email Address" />
      <Input ref={rf} label="Referee Name" />
      <h6>{error}</h6>
      <button
        onClick={() => {
          sendEmail();
        }}
      >
        Submit
      </button>
      {loading && <WaveLoading />}
    </div>
  );
};

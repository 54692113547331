import { useEffect, useState } from "react";
import logo from "../../assets/images/logo.png";
import logo2 from "../../assets/images/arope.jpg";

export default function Navigation(props) {
  var jwt = require("jsonwebtoken");
  const [currentUser, setCurrentUser] = useState(null);
  const pathname = window.location.pathname.split("/");

  useEffect(() => {
 
    const token = localStorage.getItem("gfgfgggn");

    if (token) {
      try {
        var decoded = jwt.decode(token);
        setCurrentUser(decoded);
      } catch (error) {}
    }
  }, []);

  const Item = (params) => {
    const [showing, setShowing] = useState(false);

    return (
      <div
        style={{
          backgroundColor: showing ? "#60606010" : "transparent",
        }}
        onMouseLeave={() => {
          setShowing(false);
        }}
      >
        <div
          onClick={() => {
            if (params.options.length === 0) {
              window.location.href = params.url;
            }
            if (params.url === "/logout") {
              localStorage.setItem("gfgfgggn", null);
              window.location.href = "/login";
            }
          }}
          onMouseEnter={() => {
            setShowing(true);
          }}
          className={params.link == params.active ? "active" : "item"}
          style={{
            padding: params.showing ? "1em" : "5x 0 5px 0",
            gridTemplateColumns: params.showing ? "auto 1fr auto" : "auto",
          }}
        >
          <i aria-hidden="true" className={"fa " + params.icon}>
            {params.code}
          </i>
          {params.showing && <p>{params.txt}</p>}
          {params.options.length > 0 && params.showing && (
            <i className="fa fa-angle-right"></i>
          )}
        </div>
        {showing &&
          params.options.length > 0 &&
          params.options.map((item, i) => {
            return (
              <a key={i} href={params.url + "/" + item}>
                {item}
              </a>
            );
          })}
      </div>
    );
  };

  return (
    <div
      style={{ width: props.showing ? "250px" : "fit-content" }}
      className="navigation"
    >
      <div className="logo">
        <img alt="" src={props.showing ? logo : logo2} />
      </div>
      <hr />
      <Item
        url="/"
        active={pathname[1]}
        link=""
        txt="Home"
        icon="fa-home"
        options={[]}
        showing={props.showing}
      />
      {(currentUser?.Role === "Admin" ||
        currentUser?.Role === "Super Admin") && (
        <Item
          txt="Requests"
          url="/requests"
          link="requests"
          active={pathname[1]}
          icon="fa-spinner"
          options={["List", "New"]}
          showing={props.showing}
        />
      )}
      {currentUser?.Role === "Super Admin" && (
        <Item
          txt="Invoices"
          url="/invoices"
          link="invoices"
          icon="fa-dollar"
          active={pathname[1]}
          code="&#xf155;"
          options={[]}
          showing={props.showing}
        />
      )}
      {currentUser?.Role === "Super Admin" && (
        <Item
          txt="Payroll"
          url="/payroll"
          link="payroll"
          icon="fa-money"
          active={pathname[1]}
          code="&#xf0d6;"
          options={[]}
          showing={props.showing}
        />
      )}
      <Item
        txt="Uber"
        url="/uber"
        link="uber"
        active={pathname[1]}
        icon="fa-car"
        options={[]}
        showing={props.showing}
      />
      <Item
        txt="Tasks"
        url="/tasks"
        link="tasks"
        active={pathname[1]}
        icon="fa-tasks"
        options={[]}
        showing={props.showing}
      />

      {(currentUser?.Role === "Admin" ||
        currentUser?.Role === "Super Admin") && (
        <Item
          txt="Documents"
          url="/documents"
          link="documents"
          active={pathname[1]}
          icon="fa-file"
          options={[]}
          showing={props.showing}
        />
      )}

      {(currentUser?.Role === "Admin" ||
        currentUser?.Role === "Super Admin") && (
        <Item
          txt="Customers"
          url="/customers"
          link="customers"
          active={pathname[1]}
          icon="fa-user"
          options={[]}
          showing={props.showing}
        />
      )}
      {currentUser?.Role === "Super Admin" && (
        <Item
          txt="Users"
          url="/users"
          link="users"
          active={pathname[1]}
          icon="fa-user-md"
          options={[]}
          showing={props.showing}
        />
      )}
      <Item
        txt="Settings"
        url="/settings"
        link="settings"
        active={pathname[1]}
        icon="fa-gear"
        code="&#xf013;"
        options={[]}
        showing={props.showing}
      />
      <Item
        txt="Logout"
        active={pathname[1]}
        icon="fa-lock"
        url="/logout"
        options={[]}
        showing={props.showing}
      />
    </div>
  );
}

import { useEffect, useRef, useState } from "react";
import WaveLoading from "../Util/WaveLoading";
import { BsInfoSquare } from "react-icons/bs";
import { GrInProgress } from "react-icons/gr";
import { BsFillPersonCheckFill } from "react-icons/bs";

export default function SubjectsPopup(props) {
  const [isLoading, setIsLoading] = useState(null);
  const [data, setData] = useState(null);
  const [active, setActive] = useState(0);
  const [fodata, setFOData] = useState(null);
  const [refresh, setRefresh] = useState(false);
  const [jdets, setJDets] = useState(null);
  const [udets, setUDets] = useState(null);

  const [body, setBody] = useState({
    RequestID: props?.item?.DocumentID,
    UserID: null,
    Assigned: true,
  });

  useEffect(() => {
    setIsLoading(true);
    fetch(`/api/subjects/byjobid/${props.JobID}`)
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else throw Error("");
      })
      .then((data) => {
        setIsLoading(false);

        if (data.length > 0) {
          const y = data;
          let d = [];
          y.map((item) => {
            let x = item;
            x.Checks = JSON.parse(item.Checks);
            d.push(x);
          });
          setData(d);
        }
      })
      .catch((e) => {
        setIsLoading(false);
      });
  }, [props.JobID, refresh]);

  const UserList = (params) => {
    return (
      <div
        onClick={() => {
          setActive(params.index);
        }}
        style={{
          backgroundColor: active === params.index ? "#FFF5B8" : "white",
        }}
        className="u_list"
      >
        <div className="no">
          <p>{params.index + 1}</p>
        </div>

        <div>
          <p>
            <b>Name: </b>
            {params.item.Name}
          </p>
          <p>
            <b>ID/Passport: </b>
            {params.item.IDNo}
          </p>
        </div>
      </div>
    );
  };

  const Link = (params) => {
    const txt = params.link.slice(13);

    function getDocument() {
      setIsLoading(true);
      fetch(`/api/uploads/${params.link}`)
        .then((res) => {
          if (res.ok) {
            return res.blob();
          } else throw Error("");
        })
        .then((blob) => {
          const url = window.URL.createObjectURL(blob);
          window.open(url, "_blank");
          setIsLoading(false);
        })
        .catch((e) => {
          alert("File not found!");
          setIsLoading(false);
        });
    }

    return (
      <p
        onClick={() => {
          getDocument();
        }}
        className="link"
      >
        {params.index + 1}. {txt}
      </p>
    );
  };

  const SingleCheck = (params) => {
    const [cliked, setClicked] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [blob, setBlob] = useState(null);

    useEffect(() => {
      if (params.item.Status.toString() !== "false") {
        fetch(`/api/uploads/${params.item.Status}`)
          .then((res) => {
            if (res.ok) return res.blob();
            else throw Error("");
          })
          .then((blob) => {
            const url = window.URL.createObjectURL(blob);
            setBlob(url);
          })
          .catch((e) => {});
      }
    }, [cliked]);

    const UpdateStatus = (prp) => {
      const sf = useRef();
      const [error, setError] = useState("");

      function uploadReport() {
        if (sf.current.files.length === 0)
          return setError("This report is required!");
        const formData = new FormData();
        formData.append(`Reports`, sf.current.files[0]);
        formData.append(`Name`, prp.item.Name);
        setIsLoading(false);
        fetch(`/api/subjects/status/${params.id}`, {
          method: "PUT",
          credentials: "include",
          headers: {
            Accept: "application/json",
          },
          body: formData,
        })
          .then((response) => {
            if (response.ok) {
              return response.json();
            } else throw Error("");
          })
          .then((data) => {
            setIsLoading(false);
            if (data.success) {
              setError(data.success);
              setTimeout(() => {
                setClicked(false);
                setRefresh(!refresh);
              }, 500);
            } else {
              setError(data.error);
              setTimeout(() => {
                setClicked(false);
              }, 500);
            }
          })
          .catch((err) => {
            setIsLoading(false);
            setError("Oops! Something went wrong!");
            setTimeout(() => {
              setClicked(false);
            }, 500);
          });
      }

      return (
        <div className="updateprp">
          <div className="up_wrapper">
            <i
              onClick={() => {
                setClicked(false);
              }}
              className="fa fa-times"
            ></i>
            <h3>{prp.item.Name}</h3>
            <hr />
            <div className="input-container">
              <h4>Upload Report</h4>
              <input ref={sf} type="file" label="Upload file *" />
            </div>
            <h6>{error}</h6>
            <button
              onClick={() => {
                uploadReport();
              }}
            >
              Submit
            </button>
          </div>
          {isLoading && <WaveLoading />}
        </div>
      );
    };

    return (
      <>
        <div
          onClick={() => {
            if (params.item.Status.toString() === "false") {
              setClicked(true);
            } else {
              if (blob) window.open(blob, "_blank");
              else alert("File not found!");
            }
          }}
          className="p_check"
        >
          <div
            style={{ backgroundColor: params.item.Status ? "green" : "red" }}
          ></div>
          <p>{params.item.Name}</p>
        </div>
        {props.task && cliked && <UpdateStatus item={params.item} />}
      </>
    );
  };

  function getReport(link) {
    setIsLoading(true);
    fetch(`/api/uploads/${link}`)
      .then((res) => {
        if (res.ok) {
          return res.blob();
        } else throw Error("");
      })
      .then((blob) => {
        setIsLoading(false);
        const url = window.URL.createObjectURL(blob);
        window.open(url, "_blank");
      })
      .catch((e) => {
        setIsLoading(false);
        alert("File not found!");
      });
  }

  return (
    <div className="subjects">
      <div className="wcontent">
        <div className="div2equal">
          <div className="u_list">
            <h5>
              <BsInfoSquare /> Subject(s) List
            </h5>
            <div className="details">
              {data &&
                data.length > 0 &&
                data.map((item, i) => {
                  return <UserList key={i} item={item} index={i} />;
                })}
            </div>
          </div>
          <div className="u_list">
            <h5>
              <GrInProgress /> Subject Details
            </h5>
            {data && data.length > 0 && (
              <div>
                <h5>Basic Details</h5>
                <p>
                  <b>Name: </b>
                  {data[active].Name}
                </p>
                <p>
                  <b>Phone: </b>
                  {data[active].Phone}
                </p>
                <p>
                  <b>ID/Passport No.: </b>
                  {data[active].IDNo}
                </p>
                <br />
                <h5>Documents</h5>
                <hr />
                <p>
                  <b>National ID / Passport</b>
                </p>
                <Link index={0} link={data[active].NationalID} />
                <p>
                  <b>Other Documents</b>
                </p>
                {data[active].SubjectFiles.map((item, i) => {
                  return <Link key={i} index={i} link={item} />;
                })}
                <br />
                <h5>Checks</h5>
                {data[active]?.Checks?.map((item, i) => {
                  return (
                    <SingleCheck
                      key={i}
                      index={i}
                      item={item}
                      id={data[active].ID}
                    />
                  );
                })}

                <div className="pdiv">
                  <h5>
                    <BsFillPersonCheckFill /> Report
                  </h5>

                  {data && (
                    <div className="u_list">
                      <h3>Report</h3>
                      {data[active].Report != null ? (
                        <>
                          <p>
                            Report generated on:{" "}
                            {data[active].updatedAt.split("T")[0]}{" "}
                            {data[active].updatedAt
                              .split("T")[1]
                              .substring(0, 5)}
                          </p>

                          <button
                            onClick={() => {
                              getReport(data[active].Report);
                            }}
                          >
                            View Report
                          </button>
                        </>
                      ) : (
                        <>
                          <p>Background checking is not complete</p>
                        </>
                      )}
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>

        {isLoading && <WaveLoading />}
      </div>
    </div>
  );
}

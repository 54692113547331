import React from "react";
import "../Styles/landing.scss";
import Header from "./../components/Util/Header1";
import Footer from "../components/Util/footer";
import Hero from "../components/Landing/Hero";

export default function Landing() {

  return (
    <div className="landing">
      <Header  />
      <Hero  />
      <Footer />
    </div>
  );
}

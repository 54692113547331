import { useEffect, useState } from "react";
import { BsEmojiSmile } from "react-icons/bs";
import { FiRefreshCcw } from "react-icons/fi";
import QueryBuilder from "./QueryBuilder";
import Uber from "./Uber";

export default function UberHome(props) {
  const [active, setActive] = useState("New Drivers");
  var jwt = require("jsonwebtoken");
  const [user, setUser] = useState("");
  const [refresh, setRefresh] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem("gfgfgggn");

    if (token) {
      try {
        var decoded = jwt.decode(token);
        if (Date.now() >= decoded.exp * 1000) {
          window.location.href = "/login";
        } else {
          setUser(decoded.Name);
        }
      } catch (error) {
        window.location.href = "/login";
      }
    } else {
      window.location.href = "/login";
    }
  }, []);

  const Item = (params) => {
    return (
      <div
        onClick={() => {
          setActive(params.txt);
        }}
        className={params.txt === active ? "active" : "item"}
      >
        <div></div>
        <h3>{params.txt}</h3>
      </div>
    );
  };

  return (
    <div className="uber">
      <div className="welcome">
        <div>
          <h1>Welcome to the UBER MODULE</h1>
          <p>
            Let's do our best today <BsEmojiSmile color="orange" />
          </p>
        </div>
        <FiRefreshCcw
          onClick={() => {
            setRefresh(!refresh);
          }}
          className="refresh"
        />
      </div>

      <div className="uberbars">
        <div className="cont">
          <Item txt="New Drivers" />
          <Item txt="Driver Renewals" />
          <Item txt="New Riders" />
          <Item txt="Rider Renewals" />
          <Item txt="Data" />
        </div>
      </div>

      {active === "New Drivers" && (
        <Uber url="New Driver" setSelected={setActive} active={active} />
      )}
      {active === "Driver Renewals" && (
        <Uber url="Driver Renewal" setSelected={setActive} active={active} />
      )}
      {active === "New Riders" && (
        <Uber url="New Rider" setSelected={setActive} active={active} />
      )}
      {active === "Rider Renewals" && (
        <Uber url="Rider Renewal" setSelected={setActive} active={active} />
      )}
      {active === "Data" && (
        <QueryBuilder
          url="Rider Renewal"
          setSelected={setActive}
          active={active}
        />
      )}
    </div>
  );
}

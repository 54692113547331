import { useEffect, useRef, useState } from "react";
import "../../Styles/requests.scss";
import Input from "../Util/Input";
import WaveLoading from "../Util/WaveLoading";
import Loading from "../Util/Loading";
import UserBox from "./UserBox";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAdd, faSearch, faTimes } from "@fortawesome/free-solid-svg-icons";
import Button from "../Util/Button";

export default function NewDocument(props) {
  const [refresh, setRefresh] = useState(false);
  const [documentId, setDocumentID] = useState(null);
  const [active, setActive] = useState("Step 1");
  const [showing, setShowing] = useState(true);

  const handleResize = () => {
    if (window.innerWidth < 768) {
      setShowing(false);
    } else {
      setShowing(true);
    }
  };

  useEffect(() => {
    handleResize();
  }, []);

  var jwt = require("jsonwebtoken");

  useEffect(() => {
    const token = localStorage.getItem("gfgfgggn");
    if (token) {
      try {
        var decoded = jwt.decode(token);
        if (decoded.Role === "Admin" || decoded.Role === "Super Admin") {
        } else window.location.href = "/";
      } catch (error) {}
    }
  }, []);

  useEffect(() => {
    const token = localStorage.getItem("request");
    const step2 = localStorage.getItem("step2");
    if (token) {
      setDocumentID(token);
      if (!step2) {
        setActive("Step 2");
      } else {
        setActive("Step 3");
      }
    } else {
      setActive("Step 1");
    }
  }, [refresh]);

  const BarItem = (params) => {
    return (
      <p className={params.txt === params.active ? "active" : ""}>
        {params.txt}
      </p>
    );
  };

  return (
    <div className="requests">
      <div className="list">
        <h3>New Request</h3>
        {/* <hr /> */}
        <div className="bar">
          <BarItem txt="Step 1" active={active} />
          <BarItem txt="Step 2" active={active} />
          <BarItem txt="Step 3" active={active} />
        </div>
        <div className="new">
          {active === "Step 1" && (
            <Step1 refresh={refresh} setRefresh={setRefresh} />
          )}
          {active === "Step 2" && (
            <Step2
              refresh={refresh}
              setRefresh={setRefresh}
              JobID={documentId}
            />
          )}
          {active === "Step 3" && (
            <Step3
              refresh={refresh}
              setRefresh={setRefresh}
              JobID={documentId}
            />
          )}
        </div>
      </div>
    </div>
  );
}

const Step1 = (props) => {
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(null);
  const [userDetails, setUserDetails] = useState(null);
  const [data, setData] = useState(null);
  const tt = useRef();
  const desc = useRef();
  const sf = useRef();
  const [body, setBody] = useState({
    UserID: null,
    Title: null,
    Description: null,
    Consent: null,
  });
  const [clicked, setClicked] = useState(false);
  const [refresh, setRefresh] = useState(false);

  const createDocument = (e) => {
    if (!userDetails) return setError("Select a customer");
    setError("");
    let d = body;
    d.UserID = userDetails.UserID;
    d.Title = tt.current.value;
    d.Description = desc.current.value;
    d.Consent = sf.current.files[0];
    setBody(d);
    const chck = Object.values(d);
    let valid = true;
    chck.map((item) => {
      if (item === "" || item === null) {
        valid = false;
      }
    });
    if (!valid) return setError("All fields are required!");
    if (d.Consent === undefined) return setError("File is required!");
    setLoading(true);

    const formData = new FormData();

    for (const i in d) {
      formData.append(i, d[i]);
    }

    fetch("/api/requests/create", {
      method: "POST",
      credentials: "include",
      headers: {
        Accept: "application/json",
      },
      body: formData,
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else throw Error("");
      })
      .then((data) => {
        setLoading(false);
        if (data.success) {
          setError(data.success);
          localStorage.setItem("request", data.id);
          setTimeout(() => {
            props.setRefresh(!props.refresh);
          }, 1000);
        } else {
          setError(data.error);
        }
      })
      .catch((err) => {
        setLoading(false);
        setError("Oops! Something went wrong!");
      });
  };

  function quickSearch(value) {
    setData(null);
    setUserDetails(null);
    setLoading(true);
    fetch(`/api/mobile/quicksearch/${value}`, {
      method: "get",
      credentials: "include",
    })
      .then((res) => {
        if (res.ok) return res.json();
      })
      .then((data) => {
        setLoading(false);
        setData(data);
      })
      .catch((error) => {
        setLoading(false);
      });
  }

  return (
    <div className="div2equal">
      <form
        autoComplete="none"
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <Input ref={tt} type="text" label="Title *" />
        <Input ref={desc} type="textarea" label="Description *" />

        <div className="input-container">
          <label className={`label up`}>Consent Form *</label>
          <input
            ref={sf}
            type="file"
            label="Upload file *"
            accept=".pdf,.png,.PNG,.jpg,.jpeg,.JPG,.JPEG"
            onChange={(e) => {
              if (e.target.files.length > 0) {
                let d = body;
                d.Consent = e.target.files[0];
                setBody(d);
              }
            }}
          />
        </div>

        <h6>{error}</h6>
        <button
          onClick={() => {
            createDocument();
          }}
        >
          Submit
        </button>
      </form>
      <div className="clientarea">
        <div className="utp">
          <h3>Select Customer</h3>

          <div className="search">
            <input
              type="text"
              name="search"
              id="search"
              placeholder="Name..."
              onChange={(e) => {
                const v = e.target.value;
                if (v != "") {
                  quickSearch(v);
                } else {
                  setData(null);
                }
              }}
            />
            <FontAwesomeIcon className="fa-search" icon={faSearch} />
          </div>
          <FontAwesomeIcon
            onClick={() => {
              setClicked(true);
            }}
            className="fa-add"
            icon={faAdd}
          />
        </div>

        <hr />
        <div className="list">
          {userDetails && (
            <div className="selected">
              <div className="item">
                <FontAwesomeIcon
                  onClick={() => {
                    setUserDetails(null);
                  }}
                  className="ic"
                  icon={faTimes}
                />
                <h3>Selected Customer</h3>
                <h4>Name: {userDetails.Name}</h4>
                <p>
                  {userDetails.Email}, {userDetails.Phone}
                </p>
              </div>
            </div>
          )}
          <div className="user-list">
            {data &&
              data?.data?.length > 0 &&
              data?.data?.map((item, index) => {
                return (
                  <UserBox
                    key={index}
                    item={item}
                    setData={setData}
                    setUserDetails={setUserDetails}
                  />
                );
              })}
          </div>
          {loading && <Loading />}
        </div>
      </div>
      {loading && <WaveLoading />}
      {clicked && (
        <Popup
          setClicked={setClicked}
          setRefresh={setRefresh}
          refresh={refresh}
        />
      )}
    </div>
  );
};

const Step2 = (props) => {
  const types = [
    "Identity Check",
    "Educational Check",
    "Professional Membership Check",
    "Previous Employment Check",
    "Drivers Check",
    "Criminal Record Check",
    "Domestic Workers Check",
    "Document Verification",
    "Risk Assessment Check",
    "Fraud Awareness Check",
    "Company Check",
    "Credit Check",
    "Referees Check",
    "Audit and Monitoring",
    "Comprehensive Check",
    "Other",
  ];
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(null);
  const [data, setData] = useState(null);
  const [adding, setAdding] = useState(false);
  const [checks, setChecks] = useState([
    { Name: "Identity Check", Status: false },
    { Name: "Educational Check", Status: false },
    { Name: "Referees Check", Status: false },
    { Name: "Criminal Record Check", Status: false },
    { Name: "Previous Employment Check", Status: false },
  ]);
  const nm = useRef();
  const pn = useRef();
  const sf = useRef();
  const idno = useRef();
  const nid = useRef();
  const [body, setBody] = useState({
    JobID: props.JobID,
    Name: null,
    Phone: null,
    IDNo: null,
    NationalID: null,
    Checks: null,
    SubjectFiles: null,
  });

  useEffect(() => {
    fetch(`/api/subjects/byjobid/${props.JobID}`)
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else throw Error("");
      })
      .then((data) => {
        setData(data);
      })
      .catch((e) => {});
  }, [props.refresh]);

  const createDocument = (e) => {
    setError("");
    let d = body;
    d.Name = nm.current.value;
    d.Phone = pn.current.value;
    d.Checks = JSON.stringify(checks);
    d.SubjectFiles = sf.current.files;
    d.NationalID = nid.current.files[0];
    d.IDNo = idno.current.value;
    setBody(d);
    const chck = Object.values(d);
    let valid = true;
    chck.map((item) => {
      if (item === "" || item === null) {
        valid = false;
      }
    });
    if (!valid) return setError("All fields are required!");
    if (d.NationalID === undefined)
      return setError("National ID/Passport is required!");
    if (d.SubjectFiles.length === 0)
      return setError("At least one attachment is required!");
    setLoading(true);

    const formData = new FormData();

    let x = d;
    delete x.SubjectFiles;

    for (const i in x) {
      formData.append(i, x[i]);
    }

    for (let i = 0; i < sf.current.files.length; i++) {
      formData.append(`SubjectFiles`, sf.current.files[i]);
    }

    fetch("/api/subjects/create", {
      method: "POST",
      credentials: "include",
      headers: {
        Accept: "application/json",
      },
      body: formData,
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else throw Error("");
      })
      .then((data) => {
        setLoading(false);
        if (data.success) {
          setError(data.success);

          setTimeout(() => {
            var allInputs = document.querySelectorAll("input");
            allInputs.forEach((singleInput) => (singleInput.value = ""));
            nm.current.value = "";
            pn.current.value = "";
            idno.current.value = "";
            props.setRefresh(!props.refresh);
          }, 1000);
        } else {
          setError(data.error);
        }
      })
      .catch((err) => {
        setLoading(false);
        setError("Oops! Something went wrong!");
      });
  };

  const List = (params) => {
    return (
      <div className="s_list">
        <p>{params.item.Name}</p>
        <i
          onClick={() => {
            setChecks(checks.filter((a) => a.Name !== params.item.Name));
          }}
          className="fa fa-times"
        ></i>
      </div>
    );
  };

  const Type = (params) => {
    return (
      <div
        onClick={() => {
          setChecks([...checks, { Name: params.item, Status: false }]);
          setAdding(false);
        }}
        className="t_list"
      >
        <p>{params.item}</p>
        <i className="fa fa-plus"></i>
      </div>
    );
  };

  const UserList = (params) => {
    const [chs, setChs] = useState("");
    const [atrs, setAtrs] = useState("");
    useEffect(() => {
      let d = JSON.parse(params.item.Checks);
      //d = JSON.parse(d);
      let t = "";
      d.forEach((item) => {
        t = t + item.Name + ", ";
      });
      t = t.slice(0, -2);
      setChs(t);
      //attachments
      let x = params.item.SubjectFiles;
      let a =
        params.item.NationalID.substring(13, params.item.NationalID.length) +
        ", ";
      x.forEach((item) => {
        a = a + item.substring(13, item.length) + ", ";
      });
      a = a.slice(0, -2);
      setAtrs(a);
    }, []);

    function deleteUser() {
      setLoading(true);
      fetch(`/api/subjects/${params.item.ID}`, {
        method: "DELETE",
        credentials: "include",
      })
        .then((res) => {
          if (res.ok) {
            return res.json();
          } else throw Error("");
        })
        .then((data) => {
          setLoading(false);
          props.setRefresh(!props.refresh);
        })
        .catch((e) => {
          setLoading(false);
        });
    }

    return (
      <div className="u_list">
        <i
          onClick={() => {
            deleteUser();
          }}
          className="fa fa-times"
        ></i>
        <h4>Subject {params.index + 1}</h4>
        <p>
          <b>Name: </b>
          {params.item.Name}
        </p>
        <div className="div2equal">
          <p>
            <b>Phone: </b>
            {params.item.Phone}
          </p>
          <p>
            <b>ID/Passport: </b>
            {params.item.IDNo}
          </p>
        </div>
        <p>
          <b>Checks: </b>
          {chs}
        </p>
        <p>
          <b>Attachments: </b>
          {atrs}
        </p>
      </div>
    );
  };

  return (
    <div className="div2equal">
      <form
        autoComplete="none"
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <h3>Add Subject</h3>
        <hr />
        <Input ref={nm} type="text" label="Name *" />

        <Input ref={pn} type="number" label="Phone *" />

        <Input ref={idno} type="number" label="ID/Passport No *" />

        <div className="input-container">
          <label className="label up">National ID/Passport *</label>
          <input
            ref={nid}
            type="file"
            label="Upload file *"
            accept=".pdf,.png,.PNG,.jpg,.jpeg,.JPG,.JPEG"
          />
        </div>

        <div className="input-container">
          <label className="label up">Other Attachments *</label>
          <input
            ref={sf}
            type="file"
            multiple="multiple"
            label="Upload file *"
            accept=".pdf,.png,.PNG,.jpg,.jpeg,.JPG,.JPEG"
          />
        </div>

        <div className="checks">
          <h4>Add Checks *</h4>
          <div>
            {checks &&
              checks.map((item, i) => {
                return <List key={i} index={i} item={item} />;
              })}
          </div>

          {adding && (
            <div className="dropdown">
              <div className="content">
                <h3>Background Checks</h3>
                <i
                  onClick={() => {
                    setAdding(false);
                  }}
                  className="fa fa-times"
                ></i>
                <hr />
                <div className="dlist">
                  {types.map((item, i) => {
                    return <Type key={i} item={item} />;
                  })}
                </div>
              </div>
            </div>
          )}

          <i
            onClick={() => {
              setAdding(true);
            }}
            className="fa fa-plus add"
          ></i>
        </div>

        <br></br>

        <h6>{error}</h6>
        <br />
        <button
          onClick={() => {
            createDocument();
          }}
        >
          Submit
        </button>
      </form>
      <div className="customers">
        <h3>Subject(s) List</h3>
        <hr />
        {data &&
          data.map((item, i) => {
            return <UserList key={i} item={item} index={i} />;
          })}
        <br />
        <br />
        <br />
        {data && data.length > 0 && (
          <button
            style={{ backgroundColor: "orange" }}
            onClick={() => {
              localStorage.setItem("step2", true);
              props.setRefresh(!props.refresh);
            }}
          >
            Next
          </button>
        )}
      </div>
      {loading && <WaveLoading />}
    </div>
  );
};

const Step3 = (props) => {
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(null);
  const [jdets, setJDets] = useState(null);
  const [udets, setUDets] = useState(null);
  const tt = useRef();
  const desc = useRef();
  const sf = useRef();
  const [body, setBody] = useState({
    UserID: null,
    Title: null,
    Description: null,
    Consent: null,
  });
  const [data, setData] = useState(null);

  useEffect(() => {
    setLoading(true);
    fetch(`/api/subjects/byjobid/${props.JobID}`)
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else throw Error("");
      })
      .then((data) => {
        setLoading(false);
        setData(data);
      })
      .catch((e) => {
        setLoading(false);
      });

    fetch(`/api/requests/${props.JobID}`)
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else throw Error("");
      })
      .then((data) => {
        setLoading(false);
        setJDets(data);
      })
      .catch((e) => {
        setLoading(false);
      });
  }, [props.refresh]);

  useEffect(() => {
    if (jdets) {
      fetch(`/api/mobile/${jdets.UserID}`)
        .then((res) => {
          if (res.ok) {
            return res.json();
          } else throw Error("");
        })
        .then((data) => {
          setLoading(false);
          setUDets(data);
        })
        .catch((e) => {
          setLoading(false);
        });
    }
  }, [jdets]);

  const UserList = (params) => {
    const [chs, setChs] = useState("");
    const [atrs, setAtrs] = useState("");
    useEffect(() => {
      let d = JSON.parse(params.item.Checks);
      // d = JSON.parse(d);
      let t = "";
      d.forEach((item) => {
        t = t + item.Name + ", ";
      });
      t = t.slice(0, -2);
      setChs(t);
      //attachments
      let x = params.item.SubjectFiles;
      let a =
        params.item.NationalID.substring(13, params.item.NationalID.length) +
        ", ";
      x.forEach((item) => {
        a = a + item.substring(13, item.length) + ", ";
      });
      a = a.slice(0, -2);
      setAtrs(a);
    }, []);

    function deleteUser() {
      setLoading(true);
      fetch(`/api/subjects/${params.item.ID}`, {
        method: "DELETE",
        credentials: "include",
      })
        .then((res) => {
          if (res.ok) {
            return res.json();
          } else throw Error("");
        })
        .then((data) => {
          setLoading(false);
          props.setRefresh(!props.refresh);
        })
        .catch((e) => {
          setLoading(false);
        });
    }

    return (
      <div className="u_list">
        <i
          onClick={() => {
            deleteUser();
          }}
          className="fa fa-times"
        ></i>
        <h4>Subject {params.index + 1}</h4>
        <p>
          <b>Name: </b>
          {params.item.Name}
        </p>
        <div className="div2equal">
          <p>
            <b>Phone: </b>
            {params.item.Phone}
          </p>
          <p>
            <b>ID/Passport: </b>
            {params.item.IDNo}
          </p>
        </div>
        <p>
          <b>Checks: </b>
          {chs}
        </p>
        <p>
          <b>Attachments: </b>
          {atrs}
        </p>
      </div>
    );
  };

  function finishJob() {
    setLoading(true);
    fetch(`/api/requests/${props.JobID}`, {
      method: "PUT",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({ Progress: "Received" }),
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else throw Error("");
      })
      .then((data) => {
        setLoading(false);
        if (data.success) {
          localStorage.removeItem("step2");
          localStorage.removeItem("request");
          window.location.href = "/requests/List";
        }
      })
      .catch((e) => {
        setLoading(false);
      });
  }

  return (
    <div className="div2equal">
      <div className="details">
        <h3>Job Details</h3>
        <div className="u_list">
          <p>
            <b>Title: </b> {jdets && jdets.Title}
          </p>
          <p>
            <b>Description: </b> {jdets && jdets.Description}
          </p>
          <p>
            <b>Consent Form: </b>{" "}
            {jdets && jdets.Consent.substring(13, jdets.Consent.length)}
          </p>
        </div>
        <br />
        <h3>Client Details</h3>
        <div className="u_list">
          <p>
            <b>Name: </b> {udets && udets.Organisation}
          </p>
          <p>
            <b>Phone: </b> {udets && udets.Phone}
          </p>
          <p>
            <b>Email: </b> {udets && udets.Email}
          </p>
          <p>
            <b>Organisation: </b> {udets && udets.Organisation}
          </p>
        </div>
      </div>
      <div className="customers">
        <h3>Subject(s) List</h3>
        <hr />
        {data &&
          data.map((item, i) => {
            return <UserList key={i} item={item} index={i} />;
          })}
        <br />
        <h6
          onClick={() => {
            localStorage.removeItem("step2");
            props.setRefresh(!props.refresh);
          }}
        >
          Add More Subjects
        </h6>
        <br />
        {data && data.length > 0 && (
          <button
            style={{ backgroundColor: "orange" }}
            onClick={() => {
              finishJob();
            }}
          >
            Finish
          </button>
        )}
      </div>
      {loading && <WaveLoading />}
    </div>
  );
};

const Popup = (props) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const fname = useRef();
  const org = useRef();
  const email = useRef();
  const phone = useRef();
  const password = useRef();
  const cpassword = useRef();

  function titleCase(str) {
    var splitStr = str.toLowerCase().split(" ");
    for (var i = 0; i < splitStr.length; i++) {
      splitStr[i] =
        splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }
    return splitStr.join(" ");
  }

  const createUser = () => {
    const body = {
      Name: titleCase(fname.current.value),
      Phone: phone.current.value,
      Email: email.current.value.toLowerCase().trim(),
      Organisation: org.current.value,
      Password: password.current.value,
    };
    setLoading(true);
    setError("");

    const validateForm = () => {
      let result = true;
      if (!validateEmail(body.Email)) {
        result = false;
        setError("Please Enter a valid email address!");
        setLoading(false);
        return result;
      }
      if (
        !validatePassword(body.Password) ||
        !validatePassword(cpassword.current.value)
      ) {
        result = false;
        setError("Password must be at least 6 characters!");
        setLoading(false);
        return result;
      }
      if (body.Password !== cpassword.current.value) {
        result = false;
        setError("Passwords do not match!!!");
        setLoading(false);
        return result;
      }
      if (!body.Phone || body.Phone.length !== 10) {
        result = false;
        setError("Enter a valid phone number");
        setLoading(false);
        return result;
      }
      if (!body.Organisation != "") {
        result = false;
        setError("Organization is required");
        setLoading(false);
        return result;
      }
      return result;
    };

    if (validateForm()) {
      setLoading(true);
      fetch(`/api/mobile/register`, {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(body),
      })
        .then((response) => {
          if (response.ok) {
            return response.json();
          } else throw Error("Creation Failed!!!");
        })
        .then((data) => {
          setLoading(false);
          if (data.success) {
            setError(data.success);
            setTimeout(() => {
              props.setClicked(false);
            }, 1000);
          } else {
            setError(data.error);
          }
        })
        .catch((err) => {
          setLoading(false);
        });
    }
  };

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };
  const validatePassword = (password) => {
    return password.length >= 6;
  };

  return (
    <div className="popup">
      <div className="wrap">
        <div className="head">
          <h3>New Customer</h3>
          <FontAwesomeIcon
            onClick={() => {
              props.setClicked(false);
            }}
            className="fa-times"
            icon={faTimes}
          />
        </div>

        <hr />
        <div className="new">
          <form
            onSubmit={(e) => {
              e.preventDefault();
            }}
            autoComplete="off"
          >
            <input
              autoComplete="false"
              name="hidden"
              type="text"
              style={{ display: "none" }}
            />
            <div className="div2equal">
              <Input ref={fname} type="text" label="Name *" />
              <Input ref={email} type="email" label="Email *" />
            </div>
            <div className="div2equal">
              <Input ref={phone} type="number" label="Phone *" />
              <Input
                ref={org}
                type="text"
                label="Organization (Type 'Individual' if not an organization)"
              />
            </div>

            <div className="div2equal">
              <Input ref={password} type="password" label="Password *" />
              <Input
                ref={cpassword}
                type="password"
                label="Confirm Password *"
              />
            </div>

            <h6>{error}</h6>

            <Button handleClick={createUser} value="Submit" />
          </form>
          {loading && <WaveLoading />}
        </div>
      </div>
    </div>
  );
};

import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Routes,
} from "react-router-dom";
import "./App.scss";
import { useEffect, useState } from "react";
// importing pages
import NotFound from "./Pages/404";
import AOS from "aos";
import Home from "./Pages/Home";
import ProtectedRoute from "./components/Login/ProtectedRoute";
import Landing from "./Pages/Landing";
import UberVerification from "./Pages/UberVerification";

function App() {
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <Router>
      <Routes>
        <Route path="/verify/*" element={<UberVerification />} />
        <Route path="/login" element={<Landing />} />

        <Route
          path="/payroll/payslip"
          element={<ProtectedRoute component={Home} />}
        />

        <Route path="/*" element={<ProtectedRoute component={Home} />} />

        <Route path="*" element={<NotFound />} />
      </Routes>
    </Router>
  );
}

export default App;

export default function UserBox(props) {
  return (
    <div
      className="customer-box"
      onClick={() => {
        props.setData(null)
        props.setUserDetails(props?.item);
      }}
    >
      <div className="left">
        <h2
          style={{
            backgroundColor:
              props?.item?.Organisation === "Individual" ? "#0872BC" : "orange",
          }}
        >
          {props?.item?.Organisation}
        </h2>
      </div>
      <div className="right">
        <h4 >{props?.item?.Name}</h4>
        <p >
          {props?.item?.Email},  {props?.item?.Phone}
        </p>
      </div>
    </div>
  );
}

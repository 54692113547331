import "../../Styles/payroll.scss";
import pr from "../../assets/images/payroll.png";
import bn from "../../assets/images/bonus.png";
import emp from "../../assets/images/employees.png";
import net from "../../assets/images/net.png";
import dd from "../../assets/images/deduction.png";
import st from "../../assets/images/statutory.png";
import pay from "../../assets/images/pay.png";
import CustomPieChart from "../Stats/CustomPieChart";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowRightLong,
  faEye,
  faSearch,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import Input from "../Util/Input";
import Button from "../Util/Button";
import Select from "../Util/Select";
import { useEffect, useRef, useState } from "react";
import WaveLoading from "../Util/WaveLoading";

export default function Payroll(params) {
  const [showing, setShowing] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [data, setData] = useState(null);
  const [payslip, setPayslip] = useState(null);
  const [month, setMonth] = useState("January");
  const [year, setYear] = useState("2024");
  const dummy = {
    employees: 0,
    payroll: 0,
    net: 0,
    bonus: 0,
    deductionns: 0,
    statutory: 0,
    paid: 0,
  };
  const [stats, setStats] = useState(dummy);
  const { getMonths } = require("../../assets/data/data");
  const { getYears } = require("../../assets/data/data");

  useEffect(() => {
    let today = new Date().getMonth();
    setYear(new Date().getFullYear());
    today = today.toString().length == 1 ? `0${today + 1}` : today + 1;

    const i = getMonths
      .map((e) => {
        return e.code;
      })
      .indexOf(today);
    setMonth(getMonths[i].month);
  }, [getMonths, showing]);

  useEffect(() => {
    if (payslip) {
      setData(null);

      const i = getMonths
        .map((e) => {
          return e.month;
        })
        .indexOf(month);

      fetch(`/api/employees/${getMonths[i].code}/${year}`)
        .then((res) => {
          if (res.ok) {
            return res.json();
          } else throw Error();
        })
        .then((data) => {
          const employeeIDValues = payslip.map((obj) => obj.EmployeeID);
          const filteredEmployees = data.filter(
            (employee) =>
              employee.Status === true || employeeIDValues.includes(employee.ID)
          );
          setData(filteredEmployees);
          let d = {
            employees: 0,
            payroll: 0,
            net: 0,
            deductionns: 0,
            statutory: 0,
          };
          d.employees = filteredEmployees.length;
          filteredEmployees.map((item) => {
            d.payroll += item.Gross;
            d.statutory += calculateStatutory(item);
            d.deductionns += calculateDeductions(item);
            d.net +=
              item.Gross - calculateStatutory(item) - calculateDeductions(item);
          });
          setStats((prevStats) => ({ ...prevStats, ...d }));
        })
        .catch((e) => {});
    }
  }, [payslip, refresh]);

  function searchEmployees(q) {
    if (payslip) {
      setData(null);

      const i = getMonths
        .map((e) => {
          return e.month;
        })
        .indexOf(month);

      fetch(`/api/employees/search/${getMonths[i].code}/${year}/${q}`)
        .then((res) => {
          if (res.ok) {
            return res.json();
          } else throw Error();
        })
        .then((data) => {
          const employeeIDValues = payslip.map((obj) => obj.EmployeeID);
          const filteredEmployees = data.filter(
            (employee) =>
              employee.Status === true || employeeIDValues.includes(employee.ID)
          );
          setData(filteredEmployees);
        })
        .catch((e) => {});
    }
  }

  useEffect(() => {
    setPayslip(null);
    setStats({ ...stats, dummy });
    let i = getMonths
      .map((e) => {
        return e.month;
      })
      .indexOf(month);

    fetch(`/api/payslip/bymonth/${getMonths[i].code}/${year}`)
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else throw Error();
      })
      .then((data) => {
        setPayslip(data);
        let d = { bonus: 0, paid: 0 };
        data.map((item) => {
          d.bonus += item.Bonus;
          d.paid += item.Gross;
        });
        setStats((prevStats) => ({ ...prevStats, ...d }));
      })
      .catch((e) => {});
  }, [month, getMonths, year, showing]);

  return (
    <div className="payroll">
      <div className="leading">
        <h1>Payroll Module</h1>
        <button
          onClick={() => {
            setShowing(true);
          }}
        >
          New Employee
        </button>
      </div>
      <div className="container">
        <div className="top">
          <div className="items-list">
            <Item
              currency={false}
              img={emp}
              title="Total Employees"
              value={stats.employees}
            />
            <Item
              currency={true}
              img={pr}
              title="Payroll Cost"
              value={stats.payroll}
            />
            <Item
              currency={true}
              img={net}
              title="Net Salary"
              value={stats.net}
            />
            <Item currency={false} img={bn} title="Bonus" value={stats.bonus} />
            <Item
              currency={true}
              img={dd}
              title="Deductions"
              value={stats.deductionns}
            />
            <Item
              currency={true}
              img={st}
              title="Statutory"
              value={stats.statutory}
            />
          </div>
          <div className="pay">
            <img src={pay} alt="" />
            <p>Payroll Date</p>
            <h4>05/05/2024</h4>
            <h6>Payroll run: 05/04/2024 - 05/05/2024</h6>
            <button
              onClick={() => {
                window.location.href = "/payroll/employees";
              }}
            >
              Employees Details
            </button>
          </div>
          <div className="chart">
            <h3>Payment Status</h3>
            <CustomPieChart
              data={[
                { name: "Paid", value: stats.paid },
                { name: "Not Paid", value: stats.payroll - stats.paid },
              ]}
              aspect={0.9}
              color={["green", "orange"]}
            />
          </div>
        </div>
        <br />
        <div className="intro">
          <h4>Employee Payroll</h4>
          <div className="div1auto">
            <div className="search">
              <input
                onChange={(e) => {
                  if (e.target.value != "") {
                    searchEmployees(e.target.value);
                  } else {
                    setRefresh(!refresh);
                  }
                }}
                type="text"
                placeholder="Search for employee"
              />
              <FontAwesomeIcon className="ic" icon={faSearch} />
            </div>

            <select
              onChange={(e) => {
                setMonth(getMonths[e.target.selectedIndex].month);
              }}
              value={month}
            >
              {getMonths.map((item) => {
                return (
                  <option key={item.month} value={item.month}>
                    {item.month}
                  </option>
                );
              })}
            </select>
            <select
              onChange={(e) => {
                setYear(e.target.value);
              }}
              value={year}
            >
              {getYears.map((item) => {
                return (
                  <option key={item} value={item}>
                    {item}
                  </option>
                );
              })}
            </select>
          </div>
        </div>
        <div className="list">
          <div className="head">
            <h4>No</h4>
            <h4>Name</h4>
            <h4>Employee No</h4>
            <h4>Gross Pay</h4>
            <h4>Statutory Pay</h4>
            <h4>Deductions</h4>
            <h4>Net Pay</h4>
            <h4>Status</h4>
            <h4>Pay Slip</h4>
          </div>
          {data &&
            data.map((item, i) => {
              return (
                <EmpItem
                  key={i}
                  index={i}
                  payslip={payslip}
                  item={item}
                  month={month}
                  getMonths={getMonths}
                  year={year}
                />
              );
            })}
        </div>
      </div>
      {showing && <NewEmployee setShowing={setShowing} />}
    </div>
  );
}

const Item = (props) => {
  return (
    <div className="item">
      <img src={props.img} alt="" />
      <h4>{props.title}</h4>
      <h1>
        {props.currency ? "KSh " : ""}
        {props.value.toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })}
      </h1>
      <p>May 2024</p>
    </div>
  );
};

const EmpItem = (props) => {
  const [status, setStatus] = useState("Not Paid");
  const [showing, setShowing] = useState(false);
  const [payData, setPayData] = useState(null);
  const [code, setCode] = useState("01");

  function handleClick() {
    window.location.href =
      "/payroll/payslip/" + props.item.ID + "/" + code + "/" + props.year;
  }

  useEffect(() => {
    let i = props.getMonths
      .map((e) => {
        return e.month;
      })
      .indexOf(props.month);
    setCode(props.getMonths[i].code);
    setStatus("Not Paid");
    setPayData(null);
    if (props.payslip && props.payslip.length > 0) {
      const index = props.payslip
        .map((e) => {
          return e.EmployeeID;
        })
        .indexOf(props.item.ID);
      if (index != -1) {
        setStatus("Paid");
        setPayData(props.payslip[index]);
      }
    }
  }, [props.item.ID, props.payslip, props.month]);

  return (
    <>
      <div
        onClick={() => {
          if (status == "Paid") {
            handleClick();
          } else {
            setShowing(true);
          }
        }}
        className="row"
      >
        <p>{props.index + 1}</p>
        <p>{props.item.Name}</p>
        <p>{props.item.EmployeeNo}</p>
        <p>
          {props.item.Gross.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}
        </p>
        <p>
          {calculateStatutory(props.item).toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}
        </p>
        <p>
          {calculateDeductions(props.item).toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}
        </p>
        <p>
          {(
            props.item.Gross -
            calculateStatutory(props.item) -
            calculateDeductions(props.item)
          ).toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}
        </p>
        <p className={status == "Paid" ? "paid" : "not-paid"}>{status}</p>
        <p>
          <FontAwesomeIcon
            className="ic"
            icon={status == "Paid" ? faEye : faArrowRightLong}
          />
        </p>
      </div>
      {showing && payData == null && (
        <NewPayCheck
          item={props.item}
          setShowing={setShowing}
          month={code}
          year={props.year}
        />
      )}
    </>
  );
};

const NewPayCheck = (props) => {
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [net, setNet] = useState(0);
  const [date, setDate] = useState("2023-12-31");
  const [bonus, setBonus] = useState(0);
  const [other, setOther] = useState(0);
  const pn = useRef();
  const bn = useRef();

  useEffect(() => {
    setDate(getLastDateOfPreviousMonth(props.month, props.year));
    let d =
      props.item.Gross -
      other -
      calculateDeductions(props.item) -
      calculateStatutory(props.item) +
      bonus;
    setNet(d);
  }, [bonus, other, props.item]);

  function createEmployee() {
    const body = {
      EmployeeID: props.item.ID,
      Gross: props.item.Gross,
      PAYE: props.item.PAYE ? props.item.Gross * 0.16 : 0,
      NHIF: props.item.NHIF ? 1500 : 0,
      NSSF: props.item.NSSF ? 2160 : 0,
      Pension: props.item.Pension,
      HELB: props.item.HELB,
      Loan: props.item.Loan,
      HousingLevy: props.item.HousingLevy ? props.item.Gross * 0.0275 : 0,
      NetSalary: net,
      Other: other,
      Bonus: bonus,
      Date: pn.current.value,
    };

    const chck = Object.values(body);
    let valid = true;
    chck.map((item) => {
      if (item === null || item === "") {
        valid = false;
      }
    });
    if (!valid) return setError("All fields are required!");

    setLoading(true);

    fetch("/api/payslip/create", {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(body),
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else throw Error("");
      })
      .then((data) => {
        setLoading(false);
        if (data.success) {
          setError(data.success);
          setTimeout(() => {
            window.location.href =
              "/payroll/payslip/" +
              props.item.ID +
              "/" +
              body.Date.split("-")[1] +
              "/" +
              body.Date.split("-")[0];
          }, 1000);
        } else {
          setError(data.error);
        }
      })
      .catch((err) => {
        setLoading(false);
        setError("Oops! Something went wrong!");
      });
  }

  return (
    <div className="popup">
      <div className="mrap">
        <div className="bar">
          <h4>Pay - {props.item.Name}</h4>
          <FontAwesomeIcon
            onClick={() => {
              props.setShowing(false);
            }}
            icon={faTimes}
          />
        </div>
        <form
          onSubmit={(e) => {
            e.preventDefault();
          }}
          action=""
        >
          <div className="section">
            <div className="div2equal">
              <h4>Employee No.</h4>
              <p>{props.item.EmployeeNo}</p>
            </div>
            <div className="div2equal">
              <h4>Gross Pay</h4>
              <p>
                {props.item.Gross.toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </p>
            </div>
            <div className="div2equal">
              <h4>Bonus (type amount)</h4>
              <input
                type="number"
                onChange={(v) => {
                  if (v.target.value != "") {
                    setBonus(parseInt(v.target.value));
                  } else setBonus(0);
                }}
                ref={bn}
                value={bonus}
                placeholder="Type amount..."
              />
            </div>
            <div className="div2equal">
              <h3>Total Earnings</h3>
              <p>
                {(props.item.Gross + bonus).toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </p>
            </div>
            <div className="div2equal">
              <h4>PAYE</h4>
              <p>
                {props.item.PAYE
                  ? (props.item.Gross * 0.16).toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })
                  : "0.00"}
              </p>
            </div>
            <div className="div2equal">
              <h4>NHIF</h4>
              <p>
                {props.item.SHIF
                  ? "1,500.00".toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })
                  : "0.00"}
              </p>
            </div>
            <div className="div2equal">
              <h4>NSSF</h4>
              <p>
                {props.item.NSSF
                  ? "2,160.00".toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })
                  : "0.00"}
              </p>
            </div>
            <div className="div2equal">
              <h4>HELB</h4>
              <p>{props.item.HELB}</p>
            </div>
            <div className="div2equal">
              <h4>Loan deductions</h4>
              <p>{props.item.Loan}</p>
            </div>
            <div className="div2equal">
              <h4>Company Pension Scheme</h4>
              <p>{props.item.Pension}</p>
            </div>
            <div className="div2equal">
              <h4>Housing Levy</h4>
              <p>
                {props.item.HousingLevy
                  ? (props.item.Gross * 0.0275).toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })
                  : "0.00"}
              </p>
            </div>
            <div className="div2equal">
              <h4>Other Deductions (type amount)</h4>
              <input
                type="number"
                onChange={(v) => {
                  if (v.target.value != "") {
                    setOther(parseInt(v.target.value));
                  } else setOther(0);
                }}
                ref={bn}
                value={other}
              />
            </div>
            <div className="div2equal">
              <h4>Net Salary</h4>
              <p>
                {net.toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </p>
            </div>
            <div className="div2equal">
              <h3>Total Deductions</h3>
              <p>
                {(props.item.Gross + bonus - net).toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </p>
            </div>
            <div className="div2equal">
              <h4>Payment period (last month date):</h4>
              <input
                type="date"
                onChange={(v) => {
                  setDate(v.target.value);
                }}
                ref={pn}
                value={date}
              />
            </div>
            <h6>{error}</h6>
            <Button
              handleClick={() => {
                createEmployee();
              }}
              value="Generate Payslip"
            />
          </div>
        </form>
      </div>
      {loading && <WaveLoading />}
    </div>
  );
};

const NewEmployee = (props) => {
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const nm = useRef();
  const eno = useRef();
  const gd = useRef();
  const ds = useRef();
  const em = useRef();
  const ph = useRef();
  const gp = useRef();
  const doe = useRef();
  const paye = useRef();
  const nhif = useRef();
  const nssf = useRef();
  const pension = useRef();
  const hl = useRef();
  const hlb = useRef();
  const ln = useRef();

  const NextOfKinName = useRef();
  const NextOfKinRelationship = useRef();
  const NextOfKinContact = useRef();
  const DOB = useRef();
  const MaritalStatus = useRef();
  const IDNo = useRef();
  const College = useRef();
  const Course = useRef();
  const NSSFNumber = useRef();
  const NHIFNumber = useRef();

  function createEmployee() {
    const body = {
      Name: nm.current.value,
      EmployeeNo: eno.current.value,
      Gender: gd.current.value,
      Designation: ds.current.value,
      Email: em.current.value,
      Phone: ph.current.value,
      Gross: gp.current.value,
      DOE: doe.current.value,
      PAYE: paye.current.value,
      NHIF: nhif.current.value,
      NSSF: nssf.current.value,
      Pension: pension.current.value,
      HELB: hlb.current.value,
      Loan: ln.current.value,
      HousingLevy: hl.current.value,
      NextOfKinName: NextOfKinName.current.value,
      NextOfKinRelationship: NextOfKinRelationship.current.value,
      NextOfKinContact: NextOfKinContact.current.value,
      DOB: DOB.current.value,
      MaritalStatus: MaritalStatus.current.value,
      IDNo: IDNo.current.value,
      College: College.current.value,
      Course: Course.current.value,
      NSSFNumber: NSSFNumber.current.value,
      NHIFNumber: NHIFNumber.current.value,
    };

    const chck = Object.values(body);
    let valid = true;
    chck.map((item) => {
      if (item === null || item === "") {
        valid = false;
      }
    });
    if (!valid) return setError("All fields are required!");

    setLoading(true);

    fetch("/api/employees/create", {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(body),
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else throw Error("");
      })
      .then((data) => {
        setLoading(false);
        if (data.success) {
          setError(data.success);
          setTimeout(() => {
            props.setShowing(false);
          }, 1000);
        } else {
          setError(data.error);
        }
      })
      .catch((err) => {
        setLoading(false);
        setError("Oops! Something went wrong!");
      });
  }

  return (
    <div className="popup">
      <div className="wrap">
        <div className="bar">
          <h4>New Employee</h4>
          <FontAwesomeIcon
            onClick={() => {
              props.setShowing(false);
            }}
            icon={faTimes}
          />
        </div>
        <form
          onSubmit={(e) => {
            e.preventDefault();
          }}
          action=""
        >
          <h4>Personal Details</h4>
          <div className="div2equal">
            <Input ref={nm} label="Name" />
            <Select ref={gd} label="Gender" data={["Male", "Female"]} />
          </div>{" "}
          <div className="div2equal">
            <Input ref={em} label="Email" type="email" />
            <Input ref={ph} label="Phone" type="phone" />
          </div>{" "}
          <div className="div3equal">
            <Input
              ref={DOB}
              label="Date of Birth"
              type="date"
              value={new Date().toISOString().split("T")[0]}
            />
            <Input ref={IDNo} label="National ID No." type="number" />
            <Select
              ref={MaritalStatus}
              label="Marital Status"
              data={[
                "Single",
                "Engaged",
                "Married",
                "Widow",
                "Widower",
                "Divorced",
              ]}
            />
          </div>{" "}
          <div className="div3equal">
            <Input ref={NextOfKinName} label="Next of Kin" />
            <Select
              ref={NextOfKinRelationship}
              data={[
                "Mother",
                "Father",
                "Spouse",
                "Sibling",
                "Guardian",
                "Relative",
                "Other",
              ]}
              label="Relationship"
            />
            <Input ref={NextOfKinContact} label="Contact" type="number" />
          </div>
          <h4>Level of Education</h4>
          <div className="div2equal">
            <Input ref={College} label="School/College" type="text" />
            <Input ref={Course} label="Degree/Certificate" type="text" />
          </div>
          <h4>Employment Details</h4>
          <div className="div3equal">
            <Input ref={eno} label="Employee No" />
            <Input ref={ds} label="Designation" />
            <Input
              ref={doe}
              label="Date of Employment"
              value={new Date().toISOString().split("T")[0]}
              type="date"
            />
          </div>
          <div className="div2equal">
            <Input ref={NHIFNumber} label="NHIF Number" type="text" />
            <Input ref={NSSFNumber} label="NSSF Number" type="text" />
          </div>
          <h4>Payment Details</h4>
          <Input ref={gp} label="Gross Pay" type="number" />
          <div className="div3equal">
            <Input ref={hlb} label="HELB" value={0} type="number" />
            <Input ref={pension} label="Pension" value={0} type="number" />
            <Input ref={ln} label="Loan(s)" value={0} type="number" />
          </div>
          <div className="checkbox">
            <input ref={paye} type="checkbox" name="" id="" />
            <p>Pay As You Earn (PAYE) deduction?</p>
          </div>
          <div className="checkbox">
            <input ref={nhif} type="checkbox" name="" id="" />
            <p>NHIF deduction?</p>
          </div>
          <div className="checkbox">
            <input ref={nssf} type="checkbox" name="" id="" />
            <p>NSSF deduction?</p>
          </div>
          <div className="checkbox">
            <input ref={hl} type="checkbox" name="" id="" />
            <p>Housing levy deduction?</p>
          </div>
          <h6>{error}</h6>
          <Button
            handleClick={() => {
              createEmployee();
            }}
            value="Submit"
          />
        </form>
      </div>
      {loading && <WaveLoading />}
    </div>
  );
};

function calculateStatutory(item) {
  let d = 0;

  if (item.PAYE) {
    d += item.Gross * 0.16;
  }
  if (item.NHIF) {
    d += 1500;
  }
  if (item.NSSF) {
    d += 2160;
  }
  if (item.HousingLevy) {
    d += item.Gross * 0.0275;
  }
  return d;
}

function calculateDeductions(item) {
  return item.HELB + item.Loan + item.Pension;
}

function getLastDateOfPreviousMonth(m, y) {
  // Get the current date
  let currentDate = new Date(`${y}-${m}-01`);

  // Set the date to the first day of the current month
  currentDate.setDate(1);

  // Subtract one day to get the last day of the previous month
  currentDate.setDate(currentDate.getDate() - 1);

  // Format the date to yyyy-mm-dd
  let year = currentDate.getFullYear();
  let month = String(currentDate.getMonth() + 1).padStart(2, "0"); // getMonth() is zero-based
  let day = String(currentDate.getDate()).padStart(2, "0");

  return `${year}-${month}-${day}`;
}

import { useEffect, useRef, useState } from "react";
import "../../Styles/ubertask.scss";
import WaveLoading from "../Util/WaveLoading";
import { BsInfoSquare } from "react-icons/bs";
import { Watermark } from "@hirohe/react-watermark";
import { BsFillPersonCheckFill } from "react-icons/bs";
import { useLocation } from "react-router-dom";
import logo from "../../assets/images/logo.png";
import QRCode from "react-qr-code";
import GenericPdfDownloader from "./GenericPDFDownloader";

export default function UberTask(props) {
  const [isLoading, setIsLoading] = useState(null);
  const [data, setData] = useState(null);
  const [showing, setShowing] = useState(null);
  const [refresh, setRefresh] = useState(false);
  const [jdets, setJDets] = useState(null);
  const [udets, setUDets] = useState(null);
  const [tt, setTT] = useState(null);
  const [ct, setCT] = useState(null);
  const location = useLocation();
  const id = location.pathname.split("/")[2];
  const [rdata, rsetData] = useState(null);
  const [edit, setEdit] = useState(false);
  const [dledit, dlsetEdit] = useState(false);
  const [psvedit, psvsetEdit] = useState(false);
  const [pcedit, pcsetEdit] = useState(false);
  const refId = useRef();
  const refIdCheck = useRef();
  const refDLCheck = useRef();
  const refPSVCheck = useRef();
  const refPCCheck = useRef();
  var jwt = require("jsonwebtoken");
  const [currentUser, setCurrentUser] = useState(null);
  useEffect(() => {
    const token = localStorage.getItem("gfgfgggn");

    if (token) {
      try {
        var decoded = jwt.decode(token);
        setCurrentUser(decoded);
      } catch (error) {}
    }
  }, []);

  useEffect(() => {
    setIsLoading(true);

    fetch(`/api/uber/${id}`)
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else throw Error("");
      })
      .then((data) => {
        setIsLoading(false);
        setJDets(data);
      })
      .catch((e) => {
        setIsLoading(false);
      });
  }, [refresh]);

  useEffect(() => {
    if (jdets) {
      setIsLoading(true);
      fetch(`/api/uberusers/${jdets?.UserID}`)
        .then((res) => {
          if (res.ok) {
            return res.json();
          } else throw Error("");
        })
        .then((data) => {
          setIsLoading(false);
          setUDets(data);
        })
        .catch((e) => {
          setIsLoading(false);
        });
    }
  }, [jdets]);

  useEffect(() => {
    setData(null);
    setIsLoading(true);
    fetch(`/api/tasks/requestid/${id}`)
      .then((res) => {
        if (res.ok) return res.json();
        else throw Error();
      })
      .then((data) => {
        if (data.length > 0) {
          setData(data[0]);
        }
        setIsLoading(false);
      })
      .catch((e) => {
        setIsLoading(false);
      });
  }, [refresh]);

  useEffect(() => {
    setIsLoading(true);
    fetch(`/api/subjects/checks/byjobid/${id}`)
      .then((res) => {
        if (res.ok) return res.json();
        else throw Error();
      })
      .then((data) => {
        if (data.length > 0) {
          let total = 0;
          let count = 0;
          data.map((item) => {
            const it = JSON.parse(item.Checks);
            total = total + it.length;
            it.map((i) => {
              if (!i.Status) {
                count += 1;
              }
            });
          });
          setTT(total);
          setCT(count);
        }
        setIsLoading(false);
      })
      .catch((e) => {
        setIsLoading(false);
      });
  }, [refresh]);

  useEffect(() => {
    if (data && data.Checking === null) {
      fetch(`/api/tasks/${data.ID}`, {
        method: "PUT",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({ Checking: false }),
      })
        .then((res) => {
          if (res.ok) return res.json();
          else throw Error();
        })
        .then((data) => {
          setRefresh(!refresh);
          setIsLoading(false);
        })
        .catch((e) => {
          setIsLoading(false);
        });
    }
  }, [data]);

  useEffect(() => {
    if (data) {
      if (ct === 0 && tt !== 0) {
        updateStatus(true, false);
      }
    }
  }, [tt, ct]);

  function updateStatus(checking, report) {
    setIsLoading(true);
    fetch(`/api/tasks/${data.ID}`, {
      method: "PUT",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({ Checking: checking, ReportPreparation: report }),
    })
      .then((res) => {
        if (res.ok) return res.json();
        else throw Error();
      })
      .then((data) => {
        setRefresh(!refresh);
        setIsLoading(false);
      })
      .catch((e) => {
        setIsLoading(false);
      });
  }

  function formatAMPM(dt) {
    let date = new Date(Date.parse(dt));
    var hours = date?.getHours();
    var minutes = date?.getMinutes();
    var ampm = hours >= 12 ? "pm" : "am";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? "0" + minutes : minutes;
    var strTime = hours + ":" + minutes + " " + ampm;
    return dt.split("T")[0] + " " + strTime;
  }

  const Link = (params) => {
    const txt = params.link.slice(13);

    function getDocument() {
      setIsLoading(true);
      fetch(`/api/uploads/${params?.link}`)
        .then((res) => {
          if (res.ok) {
            return res.blob();
          } else throw Error("");
        })
        .then((blob) => {
          const url = window.URL.createObjectURL(blob);
          window.open(url, "_blank");
          setIsLoading(false);
        })
        .catch((e) => {
          alert("File not found!");
          setIsLoading(false);
        });
    }

    return (
      <p
        onClick={() => {
          getDocument();
        }}
        className="link"
      >
        {txt}
      </p>
    );
  };

  //report

  useEffect(() => {
    if (jdets && udets) {
      fetch(`/api/uberreport/getuberid/${jdets.DocumentID}`)
        .then((res) => {
          if (res.ok) return res.json();
          else throw Error("");
        })
        .then((data) => {
          if (data.length > 0) {
            console.log(data[0]);

            rsetData(data[0]);
            setEdit(data[0].IDCheck != null ? false : true);
            psvsetEdit(data[0].PSVCheck != null ? false : true);
            dlsetEdit(data[0].DLCheck != null ? false : true);
            pcsetEdit(data[0].PoliceCheck != null ? false : true);
          } else {
            createUberTask();
          }
        })
        .catch((e) => {});
    }
  }, [jdets, udets, refresh]);

  function createUberTask() {
    const body = {
      UberID: jdets.DocumentID,
      FirstName:
        udets.Name.split(" ").length > 0 ? udets.Name.split(" ")[0] : "",
      MiddleName:
        udets.Name.split(" ").length > 2 ? udets.Name.split(" ")[1] : "",
      Surname:
        udets.Name.split(" ").length === 2
          ? udets.Name.split(" ")[1]
          : udets.Name.split(" ").length > 2
          ? udets.Name.split(" ")[2]
          : "",
      Phone: udets.Phone,
      Result: "Pending",
      Photo: jdets.Passport,
    };

    fetch(`/api/uberreport/create`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(body),
    })
      .then((res) => {
        if (res.ok) return res.json();
        else throw Error("");
      })
      .then((data) => {
        setRefresh(!refresh);
      })
      .catch((e) => {});
  }

  function updateReport(body) {
    if (!rdata) return;

    fetch(`/api/uberreport/${rdata.UberID}`, {
      method: "PUT",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(body),
    })
      .then((res) => {
        if (res.ok) return res.json();
        else throw Error("");
      })
      .then((data) => {
        setRefresh(!refresh);
      })
      .catch((e) => {});
  }

  function resetReport() {
    if (!rdata) return;

    fetch(`/api/uberreport/${rdata.UberID}`, {
      method: "PUT",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        Date: null,
        Report: null,
        Result: "Pending",
        PSVCheck: null,
        PoliceCheck: null,
        DLCheck: null,
        IDCheck: null,
        Status: false,
      }),
    })
      .then((res) => {
        if (res.ok) return res.json();
        else throw Error("");
      })
      .then((data) => {
        setRefresh(!refresh);
      })
      .catch((e) => {});
  }

  function finishReport() {
    let d = {};
    if (
      rdata &&
      rdata.PSVCheck != null &&
      rdata.DLCheck != null &&
      rdata.PoliceCheck != null &&
      rdata.IDCheck != null
    ) {
      if (
        rdata.PSVCheck.includes("NOT") ||
        rdata.DLCheck.includes("NOT") ||
        !rdata.PoliceCheck.includes("Nil") ||
        rdata.IDCheck.includes("NOT")
      ) {
        d = {
          Result: "Fail",
          Status: true,
          Date: dateNow(),
        };
      } else {
        d = {
          Result: "Pass",
          Status: true,
          Date: dateNow(),
        };
      }
      fetch(`/api/uberreport/${rdata.UberID}`, {
        method: "PUT",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(d),
      })
        .then((res) => {
          if (res.ok) return res.json();
          else throw Error("");
        })
        .then((data) => {
          setRefresh(!refresh);
        })
        .catch((e) => {});
    }
  }

  function dateNow(params) {
    const today = new Date();
    const yyyy = today.getFullYear();
    let mm = today.getMonth() + 1; // Months start at 0!
    let dd = today.getDate();

    if (dd < 10) dd = "0" + dd;
    if (mm < 10) mm = "0" + mm;

    return dd + "/" + mm + "/" + yyyy;
  }
  //report

  return (
    <>
      <div data-aos="fade-left" className="ubertask">
        <div className="pcontainer">
          <div className="wcontent">
            <div className="pdiv">
              <h5>
                <BsInfoSquare /> Basic Info
              </h5>
              <div className="details div3equal">
                <div className="u_list">
                  <h3>Job Details</h3>
                  <p>
                    <b>Uber driver check for: </b> {udets && udets?.Name}
                  </p>
                  <p>
                    <b>Type: </b> {jdets && jdets?.Category}
                  </p>
                  <p>
                    <b>Mpesa Code: </b> {jdets && jdets?.MPESA}
                  </p>
                  <p>
                    <b>National ID: </b>{" "}
                  </p>
                  {jdets && <Link index={0} link={jdets?.NationalID} />}
                  <p>
                    <b>Passport Photo: </b>{" "}
                  </p>
                  {jdets && <Link index={0} link={jdets?.Passport} />}
                  <p>
                    <b>Police Clearance Certificate: </b>{" "}
                  </p>
                  {jdets && <Link index={0} link={jdets?.Police} />}
                </div>
                <div className="u_list">
                  <h3>Driver Details</h3>
                  <p>
                    <b>Name: </b> {udets && udets?.Name}
                  </p>
                  <p>
                    <b>Phone: </b> {udets && udets?.Phone}
                  </p>
                  <p>
                    <b>Email: </b> {udets && udets?.Email}
                  </p>
                  <p>
                    <b>Date joined: </b> {udets && formatAMPM(udets?.createdAt)}
                  </p>
                </div>
                <div className="div prg u_list">
                  <h3>Progress</h3>
                  <Item txt="Received" status={true} />
                  <Item
                    txt="Actual Checks"
                    status={rdata?.Date != null ? true : false}
                  />
                  <Item
                    txt="Report Submission"
                    status={rdata?.Report != null ? true : null}
                  />
                </div>
              </div>
            </div>

            {rdata?.Report == null && (
              <div className="pdiv">
                <h5>
                  <BsFillPersonCheckFill /> Checks
                </h5>
                <div className="u_list">
                  <div className="idcheck">
                    <div className="p_check">
                      <div
                        style={{
                          backgroundColor:
                            rdata && rdata?.IDCheck != null ? "green" : "red",
                        }}
                      ></div>
                      <p>Identity Check</p>
                    </div>
                    <p>
                      {rdata && rdata?.NationalID != null
                        ? rdata?.NationalID
                        : ""}
                    </p>
                    <p>
                      {rdata && rdata?.IDCheck != null ? rdata?.IDCheck : ""}
                    </p>
                    {edit ? (
                      <div className="submit">
                        <input
                          ref={refId}
                          placeholder="Driver ID Number"
                          type="text"
                          required
                        />
                        <select ref={refIdCheck} name="" id="">
                          <option
                            value=" The National ID Card is NOT Authentic. The ID details
                          DO NOT match the records in the IPRS database"
                          >
                            The National ID Card is NOT Authentic. The ID
                            details DO NOT match the records in the IPRS
                            database
                          </option>
                          <option value="The National ID Card is Authentic. The ID details match the records in the IPRS database">
                            The National ID Card is Authentic. The ID details
                            match the records in the IPRS database
                          </option>
                        </select>
                        <button
                          onClick={() => {
                            updateReport({
                              NationalID: refId.current.value,
                              IDCheck: refIdCheck.current.value,
                            });
                          }}
                        >
                          Submit
                        </button>
                        <br />
                        <h6
                          onClick={() => {
                            setEdit(false);
                          }}
                        >
                          Cancel
                        </h6>
                      </div>
                    ) : (
                      <h6
                        onClick={() => {
                          setEdit(true);
                        }}
                      >
                        Update
                      </h6>
                    )}
                  </div>

                  <div className="idcheck">
                    <div className="p_check">
                      <div
                        style={{
                          backgroundColor:
                            rdata && rdata?.PoliceCheck != null
                              ? "green"
                              : "red",
                        }}
                      ></div>
                      <p>Police Clearance Check</p>
                    </div>
                    <p>
                      {rdata && rdata?.PoliceCheck != null
                        ? rdata?.PoliceCheck
                        : ""}
                    </p>
                    {pcedit ? (
                      <div className="submit">
                        <select ref={refPCCheck} name="" id="">
                          <option value="Criminal record(s) found as per the Criminal Records Office database">
                            Criminal record(s) found as per the Criminal Records
                            Office database
                          </option>
                          <option value="Nil criminal records as per the Criminal Records Office database">
                            Nil criminal records as per the Criminal Records
                            Office database
                          </option>
                        </select>
                        <button
                          onClick={() => {
                            updateReport({
                              PoliceCheck: refPCCheck.current.value,
                            });
                          }}
                        >
                          Submit
                        </button>
                        <br />
                        <h6
                          onClick={() => {
                            pcsetEdit(false);
                          }}
                        >
                          Cancel
                        </h6>
                      </div>
                    ) : (
                      <h6
                        onClick={() => {
                          pcsetEdit(true);
                        }}
                      >
                        Update
                      </h6>
                    )}
                  </div>

                  <div className="idcheck">
                    <div className="p_check">
                      <div
                        style={{
                          backgroundColor:
                            rdata && rdata?.DLCheck != null ? "green" : "red",
                        }}
                      ></div>
                      <p>Driver's License Check</p>
                    </div>
                    <p>
                      {rdata && rdata?.DLCheck != null ? rdata?.DLCheck : ""}
                    </p>
                    {dledit ? (
                      <div className="submit">
                        <select ref={refDLCheck} name="" id="">
                          <option value="The Driving License is NOT authentic as per the NTSA database">
                            The Driving License is NOT authentic as per the NTSA
                            database
                          </option>
                          <option value="The Driving License is authentic as per the NTSA database">
                            The Driving License is authentic as per the NTSA
                            database
                          </option>
                        </select>
                        <button
                          onClick={() => {
                            updateReport({
                              DLCheck: refDLCheck.current.value,
                            });
                          }}
                        >
                          Submit
                        </button>
                        <br />
                        <h6
                          onClick={() => {
                            dlsetEdit(false);
                          }}
                        >
                          Cancel
                        </h6>
                      </div>
                    ) : (
                      <h6
                        onClick={() => {
                          dlsetEdit(true);
                        }}
                      >
                        Update
                      </h6>
                    )}
                  </div>

                  <div className="idcheck">
                    <div className="p_check">
                      <div
                        style={{
                          backgroundColor:
                            rdata && rdata?.PSVCheck != null ? "green" : "red",
                        }}
                      ></div>
                      <p>PSV Check</p>
                    </div>
                    <p>
                      {rdata && rdata?.PSVCheck != null ? rdata?.PSVCheck : ""}
                    </p>
                    {psvedit ? (
                      <div className="submit">
                        <select ref={refPSVCheck} name="" id="">
                          <option
                            value="Driving DO license details DO NOT match the PSV details as per the
                    NTSA database"
                          >
                            Driving license details DO NOT match the PSV details
                            as per the NTSA database
                          </option>
                          <option
                            value="Driving license details match the PSV details as per the
                    NTSA database"
                          >
                            Driving license details match the PSV details as per
                            the NTSA database
                          </option>
                        </select>
                        <button
                          onClick={() => {
                            updateReport({
                              PSVCheck: refPSVCheck.current.value,
                            });
                          }}
                        >
                          Submit
                        </button>
                        <br />
                        <h6
                          onClick={() => {
                            psvsetEdit(false);
                          }}
                        >
                          Cancel
                        </h6>
                      </div>
                    ) : (
                      <h6
                        onClick={() => {
                          psvsetEdit(true);
                        }}
                      >
                        Update
                      </h6>
                    )}
                  </div>
                </div>
              </div>
            )}
            <br />

            {rdata?.Report == null && (
              <button
                onClick={() => {
                  finishReport();
                }}
              >
                Update Certificate
              </button>
            )}
            <br />
            <div className="pdiv">
              <h5>
                <BsFillPersonCheckFill /> Report
              </h5>
              <div className="u_list">
                {rdata && rdata.Report != null ? (
                  <>
                    <p>
                      Uber Certificate has been downloaded and shared with
                      client. Download again?
                    </p>
                    <button
                      onClick={() => {
                        window.open(
                          `https://api.aropegroup.com/api/uberreport/getfile/${rdata.ID}`,
                          "_blank"
                        );
                      }}
                    >
                      View/Download Report
                    </button>
                  </>
                ) : (
                  <p>Uber Certificate is not ready or it has not been saved!</p>
                )}
              </div>
            </div>

            {currentUser?.Role === "Super Admin" && (
              <div className="pdiv">
                <h5>
                  <BsFillPersonCheckFill /> Reset Report
                </h5>
                {rdata && (
                  <div className="u_list">
                    <p>Only a Super Admin User can reset the report.</p>
                    <button
                      onClick={() => {
                        resetReport();
                      }}
                      style={{ backgroundColor: "orange" }}
                    >
                      Reset Report
                    </button>
                  </div>
                )}
              </div>
            )}

            {rdata?.Report == null && (
              <UberReport data={rdata} Email={udets?.Email} />
            )}

            {isLoading && <WaveLoading />}
          </div>
        </div>
      </div>
    </>
  );
}

const Item = (props) => {
  const [color, setColor] = useState("red");

  useEffect(() => {
    switch (props.status) {
      case true:
        setColor("green");
        break;
      case false:
        setColor("orange");
        break;
      case null:
        setColor("red");
        break;
      default:
        setColor("red");
        break;
    }
  }, [props.status]);

  return (
    <div className="item">
      <div style={{ backgroundColor: color }}></div>
      <p>
        {props.txt} <b>{props.tally ? props.tally : ""}</b>
      </p>
    </div>
  );
};

const UberReport = (props) => {
  return (
    <>
      <div id="report" className="uberreport">
        {props.data && (
          <Watermark
            text={`${props.data.FirstName} ${props.data.Surname}: ${props.data.Result}`}
          >
            <div className="container">
              <div className="utop">
                <div className="logo">
                  <img src={logo} alt="" />
                  <div className="capt">
                    <hr />
                    <h4>UBER DRIVER CERTIFICATE</h4>
                    <hr />
                  </div>
                </div>
                <img
                  src={`/api/uploads/${props.data.Photo}`}
                  className="pass"
                  alt=""
                />
              </div>
              <br />
              <br />
              <div className="div3equal">
                <div className="ref">
                  <h4>National ID</h4>
                  <p>{props.data.NationalID}</p>
                </div>
                <div className="ref">
                  <h4>Arope Reference No.</h4>
                  <p>A/{props.data.SerialNo}</p>
                </div>
                <div className="ref">
                  <h4>Date</h4>
                  <p>{props.data.Date != null ? props.data.Date : ""}</p>
                </div>
              </div>

              <br />
              <br />
              <h3>Driver Details</h3>
              <div className="rpt">
                <div className="id">
                  <h4>Surname</h4>
                  <p>{props.data.Surname}</p>
                </div>
                <div className="id">
                  <h4>Middle Name</h4>
                  <p>{props.data.MiddleName}</p>
                </div>
                <div className="id">
                  <h4>First Name</h4>
                  <p>{props.data.FirstName}</p>
                </div>
                <div className="id">
                  <h4>Phone No.</h4>
                  <p>{props.data.Phone}</p>
                </div>
              </div>

              <div className="chk">
                <h3>Checks Conducted</h3>
                <div className="id">
                  <h4>Identification Check</h4>
                  <p>
                    {props.data.IDCheck != null
                      ? props.data.IDCheck
                      : "Pending"}
                  </p>
                </div>
                <div className="id">
                  <h4>Driving License Check</h4>
                  <p>
                    {props.data.DLCheck != null
                      ? props.data.DLCheck
                      : "Pending"}
                  </p>
                </div>
                <div className="id">
                  <h4>Public Service Vehicle Badge Check</h4>
                  <p>
                    {props.data.PSVCheck != null
                      ? props.data.PSVCheck
                      : "Pending"}
                  </p>
                </div>
                <div className="id">
                  <h4>Police Clearance Certificate Verification</h4>
                  <p>
                    {props.data.PoliceCheck != null
                      ? props.data.PoliceCheck
                      : "Pending"}
                  </p>
                </div>
              </div>

              <div className="passfail">
                <div className="pdiv">
                  <h6
                    style={{
                      color: props.data.Result === "Pass" ? "green" : "orange",
                    }}
                  >
                    {props.data.Result}
                  </h6>
                </div>

                <div className="qrcode">
                  <QRCode
                    size={180}
                    style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                    value={`https://admin.aropegroup.com/verify/${props.data.UberID}`}
                    viewBox={`0 0 180 180`}
                  />
                </div>
              </div>
            </div>
          </Watermark>
        )}
      </div>
      {props.data &&
        props.data.NationalID != null &&
        (props.data.Result === "Fail" || props.data.Result === "Pass") && (
          <GenericPdfDownloader
            downloadFileName={`${props.data.FirstName}_${props.data.Surname}_UberCert`}
            rootElementId="report"
            id={props.data.ID}
            email={props.Email}
            name={`${props.data.FirstName} ${props.data.Surname}`}
            refresh={props.refresh}
            setRefresh={props.setRefresh}
          />
        )}
    </>
  );
};

import { useEffect, useState } from "react";
import "../../Styles/stats.scss";
import CustomPieChart from "./CustomPieChart";
import CustomBarChart from "./CustomBarChart";
import { SiProgress } from "react-icons/si";
import GaugeChart from "react-gauge-chart";
import { MdAddTask } from "react-icons/md";
import { FiRefreshCcw } from "react-icons/fi";
import { MdIncompleteCircle } from "react-icons/md";
import { MdOutlineNewLabel } from "react-icons/md";

export default function Stats(props) {
  const [data, setData] = useState(null);
  const [uber, setUber] = useState(null);
  const [refresh, setRefresh] = useState(false);

  useEffect(() => {
    fetch(`/api/requests/homestats/top`)
      .then((res) => {
        if (res.ok) return res.json();
        else throw Error("");
      })
      .then((data) => {
        setData(data);
      })
      .catch((e) => {});
  }, [refresh]);

  useEffect(() => {
    fetch(`/api/requests/uberstats/top`)
      .then((res) => {
        if (res.ok) return res.json();
        else throw Error("");
      })
      .then((data) => {
        setUber(data);
      })
      .catch((e) => {});
  }, [refresh]);

  return (
    <div className="stats">
      <div className="welcome">
        <div>
          <h1>Arope Dashboard</h1>
        </div>
        <FiRefreshCcw
          onClick={() => {
            setRefresh(!refresh);
          }}
          className="refresh"
        />
      </div>

      <div className="taskstats">
        <div className="left">
          <div className="bar">
            <div></div>
            <h2>Background Checking</h2>
          </div>
          <div className="outer">
            <div className="ll">
              <div className="section">
                <div className="single">
                  <MdAddTask className="ts" />
                  <div>
                    <h4>{data ? data?.AllRequests : 0}</h4>
                    <p>All Jobs</p>
                  </div>
                </div>
              </div>

              <div className="section">
                <div className="single">
                  <MdIncompleteCircle className="ts" />
                  <div>
                    <h4>{data ? data?.Complete : 0}</h4>
                    <p>Completed Jobs</p>
                  </div>
                </div>
              </div>

              <div className="section">
                <div className="single">
                  <SiProgress className="ts" />
                  <div>
                    <h4>{data ? data?.AllTasks - data?.Complete : 0}</h4>
                    <p>In Progress</p>
                  </div>
                </div>
              </div>
              <div className="section">
                <div className="single">
                  <MdOutlineNewLabel className="ts" />
                  <div>
                    <h4>{data ? data?.AllRequests - data?.AllTasks : 0}</h4>
                    <p>New Jobs</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="section">
              <h3>Monthly Performance</h3>
              <CustomBarChart
                aspect={1.6}
                data={data ? data.Bar : null}
                color="orange"
              />
            </div>
          </div>
        </div>
        <div className="right">
          <div className="bar">
            <div></div>
            <h2>Performance</h2>
          </div>
          <div className="outer">
            <div className="section">
              <div className="perf">
                <h3>Performance</h3>
                <div className="hl">
                  <h4>{data ? data?.Overdue : 0}</h4>
                  <h5>Overdue</h5>
                </div>
                <GaugeChart
                  className="gg"
                  id="gauge-chart2"
                  nrOfLevels={3}
                  colors={["red", "orange", "green"]}
                  arcWidth={0.3}
                  percent={
                    data ? (data?.Complete / data?.AllTasks).toFixed(2) : 0
                  }
                  textColor="gray"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="taskstats">
        <div className="left">
          <div className="bar">
            <div></div>
            <h2>Uber Section</h2>
          </div>
          <div className="outer">
            <div className="ll">
              <div className="section">
                <div className="single">
                  <MdAddTask className="ts" />
                  <div>
                    <h4>{data ? uber?.AllRequests : 0}</h4>
                    <p>All Jobs</p>
                  </div>
                </div>
              </div>

              <div className="section">
                <div className="single">
                  <MdIncompleteCircle className="ts" />
                  <div>
                    <h4>{data ? uber?.Complete : 0}</h4>
                    <p>Completed Jobs</p>
                  </div>
                </div>
              </div>

              <div className="section">
                <div className="single">
                  <SiProgress className="ts" />
                  <div>
                    <h4>{data ? uber?.AllTasks - uber?.Complete : 0}</h4>
                    <p>In Progress</p>
                  </div>
                </div>
              </div>
              <div className="section">
                <div className="single">
                  <MdOutlineNewLabel className="ts" />
                  <div>
                    <h4>{data ? uber?.AllRequests - uber?.AllTasks : 0}</h4>
                    <p>New Jobs</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="section">
              <h3>Monthly Performance</h3>
              <CustomBarChart
                aspect={1.6}
                data={data ? data.Bar : null}
                color="orange"
              />
            </div>
          </div>
        </div>
        <div className="right">
          <div className="bar">
            <div></div>
            <h2>Distribution</h2>
          </div>
          <div className="outer">
            <div className="section">
              <div className="perf">
                <h3>Uber Jobs by Category</h3>

                <CustomPieChart
                  data={uber ? uber.Distribution : null}
                  aspect={1}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

import "../Styles/home.scss";

import Header from "../components/Util/Header";
import Navigation from "../components/Util/Navigation";
import { useState } from "react";
import Stats from "../components/Stats/Stats";
import { useEffect } from "react";
import PortalUsers from "../components/PortalUsers/PortalUsers";
import Data from "../components/Data/Data";
import Settings from "../components/Settings/Settings";
import Requests from "../components/Requests/Requests";
import NewDocument from "../components/Requests/NewDocument";
import Tasks from "../components/Tasks/Tasks";
import SingleTask from "../components/Tasks/SingleTask";
import UberTask from "../components/Uber/UberTask";
import Rider from "../components/Rider/Rider";
import RiderTask from "../components/Rider/RiderTask";
import Invoicing from "../components/Invoicing/Invoicing";
import NewInvoice from "../components/Invoicing/NewInvoice";
import PreviewInvoice from "../components/Invoicing/PreviewInvoice";
import Customers from "../components/Customers/Customers";
import UberHome from "../components/Uber/UberHome";
import ReqeustPopup from "../components/Requests/RequestPopup";
import Main from "../components/Folders/Main";
import Payroll from "../components/Payroll/Payroll";
import Payslip from "../components/Payroll/Payslip";
import { useLocation } from "react-router-dom";
import Employees from "../components/Payroll/Employees";
import ReportTemplate from "../components/Tasks/ReportTemplate";

export default function Home(props) {
  const [showing, setShowing] = useState(true);
  const [path, setPath] = useState(null);
  const [pathname, setPathname] = useState([]);
  const location = useLocation();

  useEffect(() => {
    setPathname(location.pathname.split("/"));
  }, [location.pathname]);

  const handleResize = () => {
    if (window.innerWidth < 1024) {
      setShowing(false);
    } else {
      setShowing(true);
    }
  };

  useEffect(() => {
    handleResize();
    const p = localStorage.getItem("path");

    if (p != null) setPath(p);
    else setPath("Root");
  }, []);

  return (
    <div className="home">
      <div
        style={{ gridTemplateColumns: !showing ? "auto 1fr" : "250px 1fr" }}
        className="main"
      >
        <div className="left_panel">
          <Navigation showing={showing} setShowing={setShowing} />
        </div>
        <div className="right_panel">
          <Header showing={showing} setShowing={setShowing} />
          <div className="full">
            {pathname[1] === "" && <Stats showing={showing} />}
            {pathname[1] === "customers" && <Customers />}

            {pathname[1] === "invoices" && pathname.length === 2 && (
              <Invoicing />
            )}

            {pathname[1] === "invoices" && pathname[2] === "new" && (
              <NewInvoice />
            )}

            {pathname[1] === "documents" && (
              <Main showing={showing} path={path} setPath={setPath} />
            )}

            {pathname[1] === "invoices" && pathname[2] === "preview" && (
              <PreviewInvoice />
            )}
            {pathname[1] === "users" && <PortalUsers />}

            {pathname[1] === "requests" && pathname[2] === "List" && (
              <Requests />
            )}

            {pathname[1] === "requests" && pathname[2] === "admin" && (
              <ReqeustPopup />
            )}
            {pathname[1] === "requests" && pathname[2] === "New" && (
              <NewDocument />
            )}

            {pathname[1] === "singletask" && (
              <SingleTask showing={showing} setShowing={setShowing} />
            )}

            {pathname[1] === "tasks" && (
              <Tasks showing={showing} setShowing={setShowing} />
            )}

            {pathname[1] === "createreport" && (
              <ReportTemplate showing={showing} setShowing={setShowing} />
            )}

            {pathname[1] === "ubertask" && (
              <UberTask showing={showing} setShowing={setShowing} />
            )}

            {pathname[1] === "payroll" && pathname.length == 2 && (
              <Payroll showing={showing} setShowing={setShowing} />
            )}

            {pathname[1] === "payroll" && pathname[2] === "payslip" && (
              <Payslip showing={showing} setShowing={setShowing} />
            )}

            {pathname[1] === "payroll" && pathname[2] === "employees" && (
              <Employees showing={showing} setShowing={setShowing} />
            )}

            {pathname[1] === "uber" && (
              <UberHome showing={showing} setShowing={setShowing} />
            )}

            {pathname[1] === "ridertask" && (
              <RiderTask showing={showing} setShowing={setShowing} />
            )}

            {pathname[1] === "rider" && (
              <Rider showing={showing} setShowing={setShowing} />
            )}

            {pathname[1] === "farmers" && (
              <Data
                url="/api/farmeraddress/alldata/paginated"
                title="Farmers Data"
              />
            )}

            {pathname[1] === "settings" && <Settings />}
          </div>
        </div>
      </div>
    </div>
  );
}

import { MdHourglassEmpty } from "react-icons/md";

export default function MyError(props) {
  return (
    <div className="my_error">
      <p>
        <MdHourglassEmpty size={44} className="hr" color="orange" /> {props.txt}
      </p>
    </div>
  );
}

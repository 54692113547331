import React from "react";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";

const GenericPdfDownloader = ({
  rootElementId,
  downloadFileName,
  id,
  email,
  name,
  refresh,
  setRefresh,
}) => {
  const downloadPdfDocument = () => {
    const input = document.getElementById(rootElementId);
    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("p", "mm", [canvas.width, canvas.height]);
      pdf.addImage(imgData, "JPEG", 0, 0, canvas.width, canvas.height);
      pdf.save(`${downloadFileName}.pdf`);
      const blb = pdf.output(`blob`);
      sendData(blb);
    });
  };

  function sendData(pdf) {
    const formData = new FormData();
    formData.append("Report", pdf, `${downloadFileName}.pdf`);
    formData.append("email", `${email}`);
    formData.append("name", `${name}`);
    fetch(`/api/uberreport/uploadreport/${id}`, {
      method: "PUT",
      credentials: "include",
      headers: {
        Accept: "application/json",
      },
      body: formData,
    })
      .then((res) => {
        if (res.ok) return res.json();
        else throw Error("");
      })
      .then((data) => {
        setRefresh(!refresh);
      })
      .catch((e) => {});
  }

  return (
    <div>
      <br />
      <br />
      <h6>
        This report will be exported as a PDF document, an email will be sent to
        the driver and will become available to the uber driver on the uber
        portal
      </h6>
      <br />
      <button onClick={downloadPdfDocument}>Save & Send Certificate</button>
    </div>
  );
};

export default GenericPdfDownloader;
